import { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";

import { FormHelperText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { apiServices } from "../../../services/apiServices";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { loggedIn, userProfile } from "redux/reduxSlice/UserSlice";
import swal from "sweetalert";

function ForgotPassword() {
    const inputData = {
        email: "",
    };

    const formValidation = yup.object().shape({
        email: yup.string().email().required("Email is required"),
    });

    const navigate = useNavigate();

    const [formInput] = useState(inputData);

    const handleSubmit = async (values, setSubmitting, resetForm) => {

        await apiServices
            .forgotPasswordRequest(values)
            .then((res) => {
                resetForm({ values: "" });
                toast.success("We have sent you a link in email to reset password");
                navigate("/authentication/login", { replace: true });
            })
            .catch((error) => {
                swal('Error', error.message, 'error')
            });
        setSubmitting(false);
    };

    return (
        <BasicLayout
            title="Welcome!"
            description="Use these awesome forms to login or create new account."
            image={curved6}
        >
            <Card>
                <SuiBox p={3} mb={1} textAlign="center">
                    <SuiTypography variant="h5" fontWeight="medium">
                        Forgot Password
                    </SuiTypography>
                </SuiBox>
                <SuiBox mb={2}>
                    {/* <Socials /> */}
                </SuiBox>
                {/* <Separator /> */}
                <SuiBox pt={2} pb={3} px={3}>
                    <Formik
                        initialValues={formInput}
                        validationSchema={formValidation}
                        onSubmit={(values, { setSubmitting, resetForm }) =>
                            handleSubmit(values, setSubmitting, resetForm)
                        }
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <SuiBox component="form" role="form" onSubmit={handleSubmit}>
                                <SuiBox mb={2}>
                                    <SuiInput
                                        error={touched.email && errors.email && true}
                                        success={touched.email && !errors.email && true}
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        value={values.email || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <FormHelperText error={true}>
                                        {touched.email && errors.email && errors.email}
                                    </FormHelperText>
                                </SuiBox>

                                <SuiBox mt={4} mb={1}>
                                    <SuiButton
                                        variant="gradient"
                                        color="dark"
                                        fullWidth
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        Send email
                                    </SuiButton>
                                </SuiBox>

                            </SuiBox>
                        )}
                    </Formik>
                </SuiBox>
            </Card>
        </BasicLayout>
    );
}

export default ForgotPassword;
