import {
  Card,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { apiServices } from "services/apiServices";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { Android, ArrowDownward } from "@mui/icons-material";
import { constants } from "constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function Edit(props) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const theme = useTheme({
    props: {
      MuiSelect: {
        IconComponent: ArrowDownward,
      },
    },
  });
  const { state } = useLocation();
  const [key, setKey] = useState(false);
  const [err, createError] = useState();

  const inputData = {
    logo: state.logo,
    title: state.title,
    description: state.description,
    status: state.status,
    type: state.type,
    platform: state.platform,
    minimumOs: getNumber(state.platform),
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
  ];

  function getNumber(data) {
    return data.map((res, i) => state.minimumOs[i][res]);
  }

  const formValidation = yup.object().shape({
    logo: yup
      .mixed()
      .nullable() 
      .test(
        "size",
        "uploaded file size not acceptable",
        (inputData, value) =>
          !value.logo ||
          (inputData.logo && inputData.logo.size < 35000 * 1024)
      )
      .test(
        "format",
        "Only the following formats are accepted: .jpeg, .jpg and .gif",
        (inputData, value) =>
          !value.logo ||
          (inputData.logo && SUPPORTED_FORMATS.includes(inputData.logo.type))
      ), 
    title: yup.string().min(1).max(25).required("Title is required"),
    description: yup
      .string()
      .min(1)
      .max(50)
      .required("Description is required"),
    platform: yup.array().min(1, "Atleast select one platform"),
  });

  const navigate = useNavigate();
  const [formInput] = useState(inputData);
  const [minimumVersionInput, setMinimumVersionInput] = useState([]);

  useEffect(() => {
    minimunversion();
  }, []);

  const onChangeMinimumVersion = (e, platform, i) => {
    minimumVersionInput[i] = { [platform]: e.target.value };
    setMinimumVersionInput(minimumVersionInput);
    //console.log(platform)
  };
  const handleSubmit = async (values, setSubmitting, resetForm) => {
    const ty = values.platform.map((p, i) =>
      minimumVersionInput[i] ? minimumVersionInput[i][p] : ""
    );
    createError(ty);
    if (ty.includes("")) {
      return;
    }

    values.minimumOs = JSON.stringify(
      values.platform.map((p, i) => minimumVersionInput[i][p])
    );

    values.platform = JSON.stringify(values.platform);

    let formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    formData.append("id", state.id);
    await apiServices
      .httpRequest("PUT", `apps/${state.id}`, formData, "Accept")
      .then((res) => {
        // resetForm({ values: "" });
        toast.success(res.message);
        navigate("../", { replace: true });
      })
      .catch((error) => toast.success(error));
    setSubmitting(false);
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  function minimunversion() {
    const fg = state.platform.map((res, i) => {
      return {
        [res]: state.minimumOs[i][res],
      };
    });
    setMinimumVersionInput(fg);
  }

  return (
    // console.log(err),
    (
      <>
        <SuiBox py={3}>
          <Card>
            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SuiTypography variant="h6">Edit Projects</SuiTypography>
              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => navigate(-1)}
              >
                Back
              </SuiButton>
            </SuiBox>
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <SuiBox pt={2} pb={3} px={3}>
                <Formik
                  initialValues={formInput}
                  validationSchema={formValidation}
                  onSubmit={(values, { setSubmitting, resetForm }) =>
                    handleSubmit(values, setSubmitting, resetForm)
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    /* and other goodies */
                  }) => (
                    <SuiBox
                      component="form"
                      role="form"
                      onSubmit={handleSubmit}
                    >
                      <SuiBox mb={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <SuiTypography variant="h6">App Logo</SuiTypography>
                            <SuiInput
                              sx={{ padding: 0 }}
                              error={touched.logo && errors.logo && true}
                              success={touched.logo && !errors.logo && true}
                              type={"file"}
                              name="logo"
                              onChange={(e) =>
                                setFieldValue("logo", e.currentTarget.files[0])
                              }
                              onBlur={handleBlur}
                            />

                            <FormHelperText error={true}>
                              {touched.logo && errors.logo && errors.logo}
                            </FormHelperText>

                            <SuiBox
                              component={ state.logo !== "" ? "img" : ""}  
                              src={ state.logo !== "" ? constants.apiUrl + state.logo  : ""}
                              alt="App Logo"
                              width="3rem"
                              shadow="sm"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <SuiTypography variant="h6">Title</SuiTypography>
                            <SuiInput
                              error={touched.title && errors.title && true}
                              success={touched.title && !errors.title && true}
                              placeholder="Title"
                              name="title"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            <FormHelperText error={true}>
                              {touched.title && errors.title && errors.title}
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <SuiTypography variant="h6">
                              Description
                            </SuiTypography>
                            <SuiInput
                              error={
                                touched.description &&
                                errors.description &&
                                true
                              }
                              success={
                                touched.description &&
                                !errors.description &&
                                true
                              }
                              placeholder="Description"
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <FormHelperText error={true}>
                              {touched.description &&
                                errors.description &&
                                errors.description}
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl sx={{ width: "100%" }}>
                              <SuiTypography variant="h6">
                                Select Status
                              </SuiTypography>
                              <Select
                                id="mui-select"
                                name="status"
                                value={values.status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                IconComponent={ExpandMoreIcon}
                              >
                                <MenuItem value={"active"}>Active</MenuItem>
                                <MenuItem value={"inactive"}>Inactive</MenuItem>
                              </Select>
                            </FormControl>
                            <FormHelperText error={true}></FormHelperText>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl sx={{ width: "100%" }}>
                              <SuiTypography variant="h6">Type</SuiTypography>
                              <Select
                                id="mui-select"
                                name="type"
                                value={values.type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                IconComponent={ExpandMoreIcon}
                              >
                                <MenuItem value={"public"}>Public</MenuItem>
                                <MenuItem value={"private"}>Private</MenuItem>
                              </Select>
                            </FormControl>
                            <FormHelperText error={true}></FormHelperText>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl
                              variant="outlined"
                              sx={{ width: "100%" }}
                            >
                              <SuiTypography variant="h6">
                                Select Platform
                              </SuiTypography>
                              <Select
                                id="mui-select"
                                name="platform"
                                multiple
                                value={
                                  typeof values.platform === "string"
                                    ? JSON.parse(values.platform)
                                    : values.platform
                                }
                                onChange={handleChange}
                                IconComponent={ExpandMoreIcon}
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    label="Chip"
                                  />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {["Android", "iOS", "Windows", "macOS", "Ubuntu"].map(
                                  (name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      style={getStyles(
                                        name,
                                        values.platform,
                                        theme
                                      )}
                                    >
                                      {name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                              <FormHelperText error={true}>
                                {touched.platform &&
                                  errors.platform &&
                                  errors.platform}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <SuiBox height="100%" mt={0.5} lineHeight={1}>
                              <SuiTypography
                                variant="button"
                                color="text"
                                fontWeight="medium"
                              >
                                <SuiTypography variant="h6">
                                  minimum OS version
                                </SuiTypography>
                                <div
                                  // id="mui-select"
                                  // name="minimuundefinedmOs"
                                  onChange={handleChange}
                                  // IconComponent={ExpandMoreIcon}
                                  value={
                                    typeof values.platform === "string"
                                      ? JSON.stringify(values.platform)
                                      : values.platform
                                  }
                                >
                                  {values.platform &&
                                    values.platform.map((type, i) => (
                                      <div key={type} value={type}>
                                        {type.toUpperCase()}
                                        <SuiInput
                                          error={
                                            touched.minimumOs &&
                                            err &&
                                            err[i] === "" &&
                                            true
                                          }
                                          success={
                                            touched.minimumOs &&
                                            err &&
                                            err[i] !== "" &&
                                            true
                                          }
                                          name={`minimumOs.${i}`}
                                          onChange={(e) =>
                                            onChangeMinimumVersion(e, type, i)
                                          }
                                          value={values.minimumOs[i]}
                                          onBlur={handleBlur}
                                        />

                                        <FormHelperText error={true}>
                                          {err &&
                                            err[i] === "" &&
                                            "Field is required!"}
                                        </FormHelperText>
                                      </div>
                                    ))}
                                </div>
                              </SuiTypography>
                            </SuiBox>
                          </Grid>
                        </Grid>
                      </SuiBox>
                      <SuiBox mt={4} mb={1}>
                        <SuiButton
                          variant="gradient"
                          color="dark"
                          size="small"
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                        >
                          Update
                        </SuiButton>
                      </SuiBox>
                    </SuiBox>
                  )}
                </Formik>
              </SuiBox>
            </SuiBox>
          </Card>
        </SuiBox>
      </>
    )
  );
}

export default Edit;
