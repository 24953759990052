/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import UserNavbar from "examples/Navbars/UserNavbar";
import UserLayout from "examples/LayoutContainers/UserLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";
import Breadcrumbs from "examples/Breadcrumbs";
import { useLocation } from "react-router-dom";

function OtherLayout({ light,children }) {
  const route = useLocation().pathname.split("/").slice(1);
  return (
    <UserLayout>
      <UserNavbar />
      {/* <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          /> */}
      <SuiBox>{children}</SuiBox>
      <Footer />
    </UserLayout>
  );
}

// // Typechecking props for the OtherLayout
// OtherLayout.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export default OtherLayout;
