import {
  Autocomplete,
  Card,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { apiServices } from "services/apiServices";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { ArrowDownward } from "@mui/icons-material";

function Create(props) {
  const inputData = {
    title: "",
    description: "",
    status: "inactive",
    apps: [],
    users: [],
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const theme = useTheme({
    props: {
      MuiSelect: {
        IconComponent: ArrowDownward,
      },
    },
  });

  const formValidation = yup.object().shape({
    title: yup.string().min(1).max(25).required("Title is required"),
    description: yup
      .string()
      .min(1)
      .max(50)
      .required("Description is required"),
    apps: yup.array().min(1, "Atleast select one app"),
    users: yup.array().min(1, "Atleast select one user"),
  });
  const navigate = useNavigate();
  const [formInput] = useState(inputData);
  const [apps, setApps] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getApps();
    getUsers();
  }, []);

  const CustomAutocomplete = withStyles({
    tag: {
      backgroundColor: "#a0a",
      height: 24,
      position: "relative",
      zIndex: 0,
      "& .MuiChip-label": {
        color: "#fff",
      },
      "& .MuiChip-deleteIcon": {
        color: "red",
      },
      "&:after": {
        content: '""',
        right: 10,
        top: 6,
        height: 12,
        width: 12,
        position: "absolute",
        backgroundColor: "white",
        zIndex: -1,
      },
    },
  })(Autocomplete);

  async function getApps() {
    try {
      const appsList = await apiServices.httpRequest("GET", "apps", null);
      setApps(appsList);
    } catch (error) {
      console.log(error);
    }
  }

  async function getUsers() {
    try {
      const usersList = await apiServices.httpRequest("GET", "users", null);
      setUsers(usersList);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    const users = values.users.map(user => user._id)
    const apps = values.apps.map(app => app._id)
    values.users = JSON.stringify(users);
    values.apps = JSON.stringify(apps);
    
    let formData = new FormData();

    for (let value in values) {
      formData.append(value, values[value]);
    }

    await apiServices
      .httpRequest("POST", "teams", formData, "Accept")
      .then((res) => {
        resetForm({ values: "" });
        toast.success(res.message);
        navigate("../", { replace: true });
      })
      .catch((error) => toast.success(error));
    setSubmitting(false);
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <>
      <SuiBox py={3}>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SuiTypography variant="h6">Create Team</SuiTypography>
            <SuiButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={() => navigate(-1)}
            >
              Back
            </SuiButton>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <SuiBox pt={2} pb={3} px={3}>
              <Formik
                initialValues={formInput}
                validationSchema={formValidation}
                onSubmit={(values, { setSubmitting, resetForm }) =>
                  handleSubmit(values, setSubmitting, resetForm)
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                  /* and other goodies */
                }) => (
                  <SuiBox component="form" role="form" onSubmit={handleSubmit}>
                    <SuiBox mb={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <SuiTypography variant="h6">Title</SuiTypography>
                          <SuiInput
                            error={touched.title && errors.title && true}
                            success={touched.title && !errors.title && true}
                            placeholder="Title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <FormHelperText error={true}>
                            {touched.title && errors.title && errors.title}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <SuiTypography variant="h6">
                            Description
                          </SuiTypography>
                          <SuiInput
                            error={
                              touched.description && errors.description && true
                            }
                            success={
                              touched.description && !errors.description && true
                            }
                            placeholder="Description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormHelperText error={true}>
                            {touched.description &&
                              errors.description &&
                              errors.description}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: "100%" }}>
                            <SuiTypography variant="h6">
                              Select Status
                            </SuiTypography>
                            <Select
                              id="mui-select"
                              name="status"
                              value={values.status}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value={"active"}>Active</MenuItem>
                              <MenuItem value={"inactive"}>Inactive</MenuItem>
                            </Select>
                          </FormControl>
                          <FormHelperText error={true}></FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        <FormControl
                            variant="outlined"
                            sx={{ width: "100%" }}
                          >
                            <SuiTypography variant="h6">
                              Select Apps
                            </SuiTypography>
                            <CustomAutocomplete
                              multiple
                              id="apps-standard"
                              options={apps}
                              name="apps"
                              onChange={(event, value) => {
                                setFieldValue("apps",[...value]);
                              }}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  placeholder="Apps search"
                                  fullWidth
                                  value={values?.apps}
                                />
                              )}
                            />
                            <FormHelperText error={true}>
                              {touched.apps && errors.apps && errors.apps}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl
                            variant="outlined"
                            sx={{ width: "100%" }}
                          >
                            <SuiTypography variant="h6">
                              Select Users
                            </SuiTypography>
                            <CustomAutocomplete
                              multiple
                              id="users-standard"
                              options={users}
                              name="users"
                              onChange={(event, value) => {
                                setFieldValue("users",[...value]);
                              }}
                              getOptionLabel={(option) => option.firstName+' '+option.lastName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  placeholder="Users search"
                                  fullWidth
                                  value={values?.users}
                                />
                              )}
                            />
                            <FormHelperText error={true}>
                              {touched.users && errors.users && errors.users}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </SuiBox>
                    <SuiBox mt={4} mb={1}>
                      <SuiButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        Create
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                )}
              </Formik>
            </SuiBox>
          </SuiBox>
        </Card>
      </SuiBox>
    </>
  );
}

export default Create;
