// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import VirtualReality from "layouts/virtual-reality";
import Profile from "layouts/profile";
import Applications from "layouts/applications";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ForgotPassword from "layouts/authentication/forgot-password";
import ResetPassword from "layouts/authentication/reset-password";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Apps from "examples/Icons/Apps";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";
import UserDashboard from "layouts/userViews/userDashboard";
import Users from "layouts/users";
import UsersIcon from "examples/Icons/UsersIcon";
import Teams from "layouts/teams";
import Userteam from "layouts/userteam";
import UserPasswordUpdate from "layouts/passwordUpdate";
import App from "layouts/apps";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <UsersIcon size="12px" />,
    component: <Users />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    route: "/projects",
    icon: <Apps size="12px" />,
    component: <Applications />,
    noCollapse: true,
  },
  {
    type: "user",
    name: "Projects",
    key: "projects",
    route: "/applications",
    icon: <Apps size="12px" />,
    component: <Applications />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Teams",
    key: "teams",
    route: "/teams",
    icon: <Apps size="12px" />,
    component: <Teams />,
    noCollapse: true,
  },
  {
    type: "user",
    name: "Teams",
    key: "userteam",
    route: "/userteam",
    icon: <UsersIcon size="12px" />,
    component: <Userteam/>,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   route: "/tables",
  //   icon: <Office size="12px" />,
  //   component: <Tables />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   route: "/billing",
  //   icon: <CreditCard size="12px" />,
  //   component: <Billing />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Virtual Reality",
  //   key: "virtual-reality",
  //   route: "/virtual-reality",
  //   icon: <Cube size="12px" />,
  //   component: <VirtualReality />,
  //   noCollapse: true,
  // },
  //{ type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <SpaceShip size="12px" />,
    component: <SignUp />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Forgot Password",
    key: "forgot-password",
    route: "/authentication/forgot-password",
    icon: <SpaceShip size="12px" />,
    component: <ForgotPassword />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Reset Password",
    key: "reset-password",
    route: "/authentication/reset-password/:hash",
    icon: <SpaceShip size="12px" />,
    component: <ResetPassword />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Update Password",
    key: "update-password",
    route: "/passwordUpdate/*",
    icon: <SpaceShip size="12px" />,
    component: <UserPasswordUpdate />,
    noCollapse: true,
  },
  {
    type: "",
    name: "User Dashboard",
    key: "home",
    route: "/user/home/:appId",
    icon: <SpaceShip size="12px" />,
    component: <UserDashboard />,
    noCollapse: true,
  },
  {
    //type: "collapse",
    name: "Application Details",
    key: "apps",
    route: "/apps/*",
    icon: <SpaceShip size="12px" />,
    component: <App />,
    noCollapse: true,
  },
];

export default routes;
