import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tables from "./Tables";
import { Route, Routes } from "react-router-dom";
import Create from "./Create";
import Edit from "./Edit";
import View from "./View"; 
import PlatfromApps from "./platformApps";
import TableUsers from "./TableUsers";
import PlatformUserfromApps from "./platformApps/userplatform";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Applications(props) {

  const userProfile = useSelector((state) => state.isLogin.profile);
  const navigate = useNavigate();   
  
  if (userProfile.userType == "user") {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Routes>
            <Route index element={<TableUsers />} />
            <Route path={`/create`} element={<Create />} />
            <Route path={`/edit/:id`} element={<Edit />} />
            <Route path={`/view/:id`} element={<View />} />
            <Route path={`/platform`} element={<PlatfromApps />} />  
            <Route path={`/user/platform`} element={<PlatformUserfromApps />} /> 
          </Routes>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );
    
  }else{
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Routes>
            <Route index element={<Tables />} />
            <Route path={`/create`} element={<Create />} />
            <Route path={`/edit/:id`} element={<Edit />} />
            <Route path={`/view/:id`} element={<View />} />
            <Route path={`/platform/:appData`} element={<PlatfromApps />} />  
            <Route path={`/user/platform`} element={<PlatformUserfromApps />} /> 
          </Routes>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    );

  }
 
}

export default Applications;
