import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";

// Soft UI Dashboard React routes
import routes from "routes";

// Soft UI Dashboard React contexts
import {
  useSoftUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/Store";
import { Provider } from "react-redux";
import PrivateWrapper from "services/PrivateWrapper";
import SignIn from "layouts/authentication/sign-in";
import { constants } from "./constants";
import Applications from "layouts/applications";
import Users from "layouts/users";
import './index.css';
import Teams from "layouts/teams";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (~['/authentication/sign-in', '/authentication/sign-up', '/authentication/forgot-password', '/authentication/reset-password/:hash', '/apps/*', '/user/home/:appId'].indexOf(route.route)) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );        
      } else {
        return (
          <Route
            exact
            path={route.route}
            element={<PrivateWrapper auth={{ isAuthenticated: false }}>{route.component}</PrivateWrapper>}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SuiBox>
  );

  return (
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={`${constants.apiUrl}logo/bishop.png`}
              brandName="BISHOP"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<PrivateWrapper auth={{ isAuthenticated: false }}><Navigate to="/dashboard" /></PrivateWrapper>} />
          <Route path="/activate-account/:email/:token" element={<SignIn />} />
          <Route path="/verify/:oldEmail/:email/:token" element={<SignIn />} />
          <Route path="/projects/*" element={<PrivateWrapper auth={{ isAuthenticated: false }}><Applications /></PrivateWrapper>} />
          <Route path="/users/*" element={<PrivateWrapper auth={{ isAuthenticated: false }}><Users /></PrivateWrapper>} />
          <Route path="/teams/*" element={<PrivateWrapper auth={{ isAuthenticated: false }}><Teams /></PrivateWrapper>} />
        </Routes>
      </ThemeProvider>
    </Provider>
  );
}
