import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tables from "./Tables";
import { Route, Routes } from "react-router-dom";
import Create from "./Create";
import Edit from "./Edit";
import View from "./View";
import AppDataAPI from "./AppDataAPI";
import PlatfromApps from "./platformApps";
import OtherLayout from "layouts/authentication/components/OtherLayout";
function App(props) { 
  return (
    <OtherLayout>
         <AppDataAPI />
    </OtherLayout>
  );
}

export default App;
