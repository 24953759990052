import {
  Autocomplete,
  Card,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { apiServices } from "services/apiServices";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { ArrowDownward } from "@mui/icons-material";

function Create(props) {
  const inputData = {
    firstName: "",
    lastName: "",
    email: "",
    userType: "user",
    status: "inactive"
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const theme = useTheme({
    props: {
      MuiSelect: {
        IconComponent: ArrowDownward,
      },
    },
  });

  const formValidation = yup.object().shape({
    firstName: yup.string().min(1).max(25).required("First name is required"),
    lastName: yup.string().min(1).max(25).required("Last name is required"),
    email: yup.string().max(255).required("Email is required"),
  });
  const navigate = useNavigate();
  const [formInput] = useState(inputData);

  const CustomAutocomplete = withStyles({
    tag: {
      backgroundColor: "#a0a",
      height: 24,
      position: "relative",
      zIndex: 0,
      "& .MuiChip-label": {
        color: "#fff",
      },
      "& .MuiChip-deleteIcon": {
        color: "red",
      },
      "&:after": {
        content: '""',
        right: 10,
        top: 6,
        height: 12,
        width: 12,
        position: "absolute",
        backgroundColor: "white",
        zIndex: -1,
      },
    },
  })(Autocomplete);

  const handleSubmit = async (values, setSubmitting, resetForm) => {
       
    let formData = new FormData();

    for (let value in values) {
      formData.append(value, values[value]);
    }

    await apiServices
      .httpRequest("POST", "users/create", formData, "Accept")
      .then((res) => {
        resetForm({ values: "" });
        toast.success(res.message);
        navigate("../", { replace: true });
      })
      .catch((error) => toast.error(error));
    setSubmitting(false);
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <>
      <SuiBox py={3}>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SuiTypography variant="h6">Create New User</SuiTypography>

            <SuiButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={() => navigate(-1)}
            >
              Back
            </SuiButton>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <SuiBox pt={2} pb={3} px={3}>
              <Formik
                initialValues={formInput}
                validationSchema={formValidation}
                onSubmit={(values, { setSubmitting, resetForm }) =>
                  handleSubmit(values, setSubmitting, resetForm)
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                  /* and other goodies */
                }) => (
                  <SuiBox component="form" role="form" onSubmit={handleSubmit}>
                    <SuiBox mb={2}>
                      <Grid container spacing={3}>

                        <Grid item xs={12} md={4}>
                          <SuiTypography variant="h6">First Name</SuiTypography>
                          <SuiInput
                            error={touched.firstName && errors.firstName && true}
                            success={touched.firstName && !errors.firstName && true}
                            placeholder="First Name"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <FormHelperText error={true}>
                            {touched.firstName && errors.firstName && errors.firstName}
                          </FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <SuiTypography variant="h6">Last Name</SuiTypography>
                          <SuiInput
                            error={touched.lastName && errors.lastName && true}
                            success={touched.lastName && !errors.lastName && true}
                            placeholder="Last Name"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <FormHelperText error={true}>
                            {touched.lastName && errors.lastName && errors.lastName}
                          </FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <SuiTypography variant="h6">Email</SuiTypography>
                          <SuiInput
                            error={touched.email && errors.email && true}
                            success={touched.email && !errors.email && true}
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <FormHelperText error={true}>
                            {touched.email && errors.email && errors.email}
                          </FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl sx={{ width: "100%" }}>
                              <SuiTypography variant="h6">
                                Select Role
                              </SuiTypography>
                              <Select
                                id="mui-select"
                                name="userType"
                                value={values.userType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <MenuItem value={"admin"}>Admin</MenuItem>
                                <MenuItem value={"user"}>User</MenuItem>
                              </Select>
                            </FormControl>
                            <FormHelperText error={true}></FormHelperText>
                          </Grid>

                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ width: "100%" }}>
                            <SuiTypography variant="h6">
                              Select Status
                            </SuiTypography>
                            <Select
                              id="mui-select"
                              name="status"
                              value={values.status}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value={"active"}>Active</MenuItem>
                              <MenuItem value={"inactive"}>Inactive</MenuItem>
                            </Select>
                          </FormControl>
                          <FormHelperText error={true}></FormHelperText>
                        </Grid>
                       
                      </Grid>
                    </SuiBox>
                    <SuiBox mt={4} mb={1}>
                      <SuiButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        Create
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                )}
              </Formik>
            </SuiBox>
          </SuiBox>
        </Card>
      </SuiBox>
    </>
  );
}

export default Create;
