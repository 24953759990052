import {
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import Table from "examples/Tables/Table";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { apiServices } from "services/apiServices";
import { toast } from "react-toastify";
import SuiBadge from "components/SuiBadge";
import SuiAvatar from "components/SuiAvatar";
import burceMars from "assets/images/bruce-mars.jpg";
import { constants } from "constants";
import StorageService from "services/StorageService";
import axios from "axios";
import moment from "moment";

function View(props) {
  const [appsColumns] = useState([
    { name: "id", align: "left" },
    { name: "title", align: "left" },
    { name: "status", align: "center" },
    { name: "action", align: "center" },
  ]);
  const [usersColumns] = useState([
    { name: "id", align: "left" },
    { name: "name", align: "left" },
    { name: "email", align: "center" },
    { name: "action", align: "center" },
  ]);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [team, setTeam] = useState([]);
  const [appsRows, setAppsRows] = useState([]);
  const [usersRows, setUsersRows] = useState([]);
  const [key, setKey] = useState(false);
  useEffect(() => {
    getTeams(data);
  }, [key, state]);

  const createAppsTableRows = (rows) => {
    let tableRows = [];
    rows.map((value, i) => {
      tableRows.push({
        id: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            #{i + 1}
          </SuiTypography>
        ),
        title: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {value.app.title}
          </SuiTypography>
        ),
        status: (
          <SuiBadge
            variant="gradient"
            badgeContent={value.app.status}
            color={value.app.status === "active" ? "success" : "error"}
            size="xs"
            container
          />
        ),
        action: (
          <>
            <Tooltip title="view">
              <IconButton data-id={value._id} onClick={() => console.log("ok")}>
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  fontSize="small"
                >
                  visibility
                </Icon>
              </IconButton>
            </Tooltip>
          </>
        ),
      });
    });
    setAppsRows(tableRows);
  };

  const createUsersTableRows = (rows) => {
    let tableRows = [];
    rows.map((value, i) => {
      tableRows.push({
        id: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            #{i + 1}
          </SuiTypography>
        ),
        name: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {value.user.firstName}
          </SuiTypography>
        ),
        email: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {value.user.email}
          </SuiTypography>
        ),
        action: (
          <>
            <Tooltip title="view">
              <IconButton
                data-id={value.user._id}
                onClick={() => console.log("ok2")}
              >
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  fontSize="small"
                >
                  visibility
                </Icon>
              </IconButton>
            </Tooltip>
          </>
        ),
      });
    });
    setUsersRows(tableRows);
  };

  async function getTeams(team) {
    try {
      const teams = await apiServices.httpRequest(
        "GET",
        `team/${team.teamId}`,
        null
      );
      setTeam(teams);
      createAppsTableRows(teams.apps);
      createUsersTableRows(teams.users);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <SuiBox py={3}>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SuiBox>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <SuiBox
                    component="img"
                    src={constants.apiUrl + "logo/teams.png"}
                    alt="Team Logo"
                    width="8rem"
                    shadow="sm"
                  />
                </Grid>
                <Grid item>
                  <SuiBox height="100%" mt={0.5} lineHeight={1}>
                    <SuiTypography variant="h5" fontWeight="medium">
                      {team.title}
                    </SuiTypography>
                    <SuiTypography
                      variant="button"
                      color="text"
                      fontWeight="medium"
                    >
                      {team.description}
                    </SuiTypography>
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
            <SuiBox>
              <SuiButton
                sx={{ mr: 1 }}
                variant="gradient"
                color="dark"
                size="small"
                onClick={() =>
                  navigate("/teams/edit", {
                    state: {
                      data,
                    },
                  })
                }
              >
                Edit
              </SuiButton>
              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => navigate(-1)}
              >
                Back
              </SuiButton>
            </SuiBox>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <SuiBox pb={3} px={3}></SuiBox>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs>
                  <SuiBox pb={3} px={6}>
                    <SuiBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                    >
                      <SuiTypography variant="h6">Apps</SuiTypography>
                    </SuiBox>
                    <Table columns={appsColumns} rows={appsRows} />
                  </SuiBox>
                </Grid>
                <Grid item xs>
                  <SuiBox pb={3} px={6}>
                    <SuiBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                    >
                      <SuiTypography variant="h6">Users</SuiTypography>
                    </SuiBox>
                    <Table columns={usersColumns} rows={usersRows} />
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
          </SuiBox>
        </Card>
      </SuiBox>
    </>
  );
}

export default View;
