import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import { FormHelperText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { errorMessages } from "../../../services/formValidation";
import { apiServices } from "../../../services/apiServices";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";

function SignUp() {
  const inputData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    userType: "user",
  };

  const formValidation = yup.object().shape({
    firstName: yup
      .string()
      .min(1)
      .max(25, errorMessages["firstNameMax"])
      .required(errorMessages["firstName"]),
    lastName: yup
      .string()
      .min(1)
      .max(25, errorMessages["lastNameMax"])
      .required(errorMessages["lastName"]),
    email: yup
      .string()
      .required(errorMessages["email"])
      .email(errorMessages["emailFormat"]),
    password: yup
      .string()
      .min(6, errorMessages["passwordMinLength"])
      .max(20, errorMessages["passwordMaxLength"])
      .required(errorMessages["password"]),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], errorMessages["confirmPasswordMatch"])
      .required(errorMessages["confirmPassword"]),
  });
  const Navigate = useNavigate();
  const [agreement, setAgremment] = useState(true);
  const [formInput] = useState(inputData);
  const isLogin = useSelector((state) => state.isLogin.value);

  useEffect(() => {
    if (isLogin) {
      Navigate("/dashboard");
    }
  }, [isLogin]);

  const handleSetAgremment = () => setAgremment(!agreement);

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    delete values.confirmPassword;
    await apiServices
      .signupRequest(values)
      .then((res) => {
        resetForm({ values: "" });
        toast.success(res.message);
        swal(res.message, "We sent you an activation link. Check your email and click on the link to verify.", "success");
        Navigate("/");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
    setSubmitting(false);
  };

  return (
    <BasicLayout
      title="Welcome!"
      description="Use these awesome forms to login or create new account."
      image={curved6}
    >
      <Card>
        <SuiBox p={3} mb={1} textAlign="center">
          <SuiTypography variant="h5" fontWeight="medium">
            Register with
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={2}>
          {/* <Socials /> */}
        </SuiBox>
        {/* <Separator /> */}
        <SuiBox pt={2} pb={3} px={3}>
          <Formik
            initialValues={formInput}
            validationSchema={formValidation}
            onSubmit={(values, { setSubmitting, resetForm }) =>
              handleSubmit(values, setSubmitting, resetForm)
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <SuiBox component="form" role="form" onSubmit={handleSubmit}>
                <SuiBox mb={2}>
                  <SuiInput
                    error={touched.firstName && errors.firstName && true}
                    success={touched.firstName && !errors.firstName && true}
                    placeholder="First Name"
                    name="firstName"
                    value={values.firstName || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <FormHelperText error={true}>
                    {touched.firstName && errors.firstName && errors.firstName}
                  </FormHelperText>
                </SuiBox>
                <SuiBox mb={2}>
                  <SuiInput
                    error={touched.lastName && errors.lastName && true}
                    success={touched.lastName && !errors.lastName && true}
                    placeholder="Last Name"
                    name="lastName"
                    value={values.lastName || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText error={true}>
                    {touched.lastName && errors.lastName && errors.lastName}
                  </FormHelperText>
                </SuiBox>
                <SuiBox mb={2}>
                  <SuiInput
                    error={touched.email && errors.email && true}
                    success={touched.email && !errors.email && true}
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={values.email || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText error={true}>
                    {touched.email && errors.email && errors.email}
                  </FormHelperText>
                </SuiBox>
                <SuiBox mb={2}>
                  <SuiInput
                    error={touched.password && errors.password && true}
                    success={touched.password && !errors.password && true}
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={values.password || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText error={true}>
                    {touched.password && errors.password && errors.password}
                  </FormHelperText>
                </SuiBox>
                <SuiBox mb={2}>
                  <SuiInput
                    error={
                      touched.confirmPassword && errors.confirmPassword && true
                    }
                    success={
                      touched.confirmPassword && !errors.confirmPassword && true
                    }
                    type="password"
                    placeholder="confirm Password"
                    name="confirmPassword"
                    value={values.confirmPassword || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText error={true}>
                    {touched.confirmPassword &&
                      errors.confirmPassword &&
                      errors.confirmPassword}
                  </FormHelperText>
                </SuiBox>
                <SuiBox display="flex" alignItems="center">
                  <Checkbox checked={agreement} onChange={handleSetAgremment} />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetAgremment}
                    sx={{ cursor: "poiner", userSelect: "none" }}
                  >
                    &nbsp;&nbsp;I agree the&nbsp;
                  </SuiTypography>
                  <SuiTypography
                    component="a"
                    href="#"
                    variant="button"
                    fontWeight="bold"
                    textGradient
                  >
                    Terms and Conditions
                  </SuiTypography>
                </SuiBox>
                <SuiBox mt={4} mb={1}>
                  <SuiButton
                    variant="gradient"
                    color="dark"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    sign up
                  </SuiButton>
                </SuiBox>
                <SuiBox mt={3} textAlign="center">
                  <SuiTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    Already Have An Account?&nbsp;
                    <SuiTypography
                      component={Link}
                      to="/authentication/sign-in"
                      variant="button"
                      color="dark"
                      fontWeight="bold"
                      textGradient
                    >
                      Sign in
                    </SuiTypography>
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            )}
          </Formik>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
