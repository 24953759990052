// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Data
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { useNavigate } from "react-router-dom";
import { apiServices } from "services/apiServices";
import React, { useEffect, useState } from "react";
import { helper } from "services/helper";

/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiProgress from "components/SuiProgress";

// Images
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import SuiBadge from "components/SuiBadge";
import {
  Avatar,
  //Button,
  capitalize,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import swal from "sweetalert";
import { constants } from "constants";
import moment from "moment";

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

function Tables(props) {
  const navigate = useNavigate();
  const [columns] = useState([
    { name: "title", align: "left" },
    { name: "description", align: "left" },
    { name: "status", align: "center" },
    { name: "apps", align: "left" },
    { name: "users", align: "left" },
    { name: "by", align: "left" },
    { name: "action", align: "center" },
  ]);
  const [rows, setRows] = useState([]);
  const [key, setKey] = useState(false);
  const [searchClassName, setSearchClassName] = useState("clearBtn hide");

  const [currentSortOrder, setCurrentSortOrder] = useState(1);
  const [currentSortField, setCurrentSortField] = useState("title");

  const debounceHandleChange = helper.debounce((e) => handleChange(e));

  const handleChange = async (e) => {
    const { value } = e.target
    setSearchClassName((value.length > 0) ? 'clearBtn' : 'clearBtn hide');
    getTeams(value);
  }

  const clearSearch = () => {
    const searchInput = document.getElementById('search');
    searchInput.value = '';
    setSearchClassName('clearBtn hide');
    getTeams();
  }

  useEffect(() => {
    getTeams();
  }, [key]);

  const appDelete = async (e) => {
    const _id = e.currentTarget.dataset.id;
    swal({
      closeOnClickOutside: false,
      title: "Are you sure?",
      text: "But you will still be able to retrieve this file.",
      icon: "error",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes, I am sure",
        },
      },
      dangerMode: true,
    }).then(async (isConfirm) => {
      if (isConfirm) {
        await apiServices
          .httpRequest("DELETE", `team/${_id}`, null)
          .then((res) => {
            setKey((prev) => !prev);
            swal("Deleted!", res.message, "success");
          })
          .catch((error) => {
            swal("Deleted!", error, "error");
          });
      } else {
        swal("Cancelled", "Your App is safe :)", "error");
        e.preventDefault();
      }
    });
  };

  function Platform({ data, index }) {
    return (
      <>
        <IconButton onClick={(e) => veiwPlatform(data)}>
          <Tooltip title="Click to view team details">
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
            >
              visibility
            </Icon>
          </Tooltip>
        </IconButton>
      </>
    );
  }

  function veiwPlatform(data) {
    navigate("/teams/view", {
      state: {
        data,
      },
    });
  }

  const createTableRows = (rows) => {
    let tableRows = [];
    rows.map((value, i) => {
      tableRows.push({
        title: (
          <SuiTypography variant="button" fontWeight="medium" sx={{ p: 2 }}>
            {value.title}
          </SuiTypography>
        ),
        description: (
          <Tooltip title={value.description}>
            <SuiTypography variant="button" color="text" fontWeight="medium">
              {value.description}
            </SuiTypography>
          </Tooltip>
        ),
        status: (
          <SuiBadge
            variant="gradient"
            badgeContent={value.status}
            color={value.status === "active" ? "success" : "error"}
            size="xs"
            container
          />
        ),
        apps: (
          <SuiTypography variant="button" fontWeight="medium">
            {value.apps?.length}
          </SuiTypography>
        ),
        users: (
          <SuiTypography variant="button" fontWeight="medium">
            {value.users?.length}
          </SuiTypography>
        ),
        by: (
          <SuiBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <SuiTypography variant="button" fontWeight="medium">
              {value.createdBy?.["firstName"]}
            </SuiTypography>
            <SuiTypography variant="caption" color="text">
              {moment(value.created).calendar()}
            </SuiTypography>
          </SuiBox>
        ),
        action: (
          <>
            <Platform
              data={{
                teamId: value._id
              }}
            />
            <IconButton onClick={() => navigate('/teams/edit', {
              state: {
                data: { teamId: value._id }
              }
            })
            }>
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
              >
                edit
              </Icon>
            </IconButton>
            <IconButton data-id={value._id} onClick={appDelete}>
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
              >
                delete
              </Icon>
            </IconButton>
          </>
        ),
      });
    });
    setRows(tableRows);
  };

  async function getTeams(searchTerm = null) {
    try {
      let search = '';
      if (searchTerm) {
        search = "?searchTerm=" + searchTerm;
      }
      const teams = await apiServices.httpRequest("GET", "teams" + search, null);
      createTableRows(teams);
    } catch (error) {
      console.log(error);
    }
  }

  const titleTemplate = (rowData) => {
    return (
      <SuiTypography variant="button" fontWeight="medium" sx={{ p: 2 }}>
        {rowData.title}
      </SuiTypography>
    )
  }
  
  const bodyTemplate = (rowData) => {
    return (
      <Tooltip title={rowData.description}>
        <SuiTypography variant="button" color="text" fontWeight="medium">
          {rowData.description}
        </SuiTypography>
      </Tooltip>
    )
  }

  const onColumnSort = (data) => {
    var newSortOrder = data.order;
    var dataArray = data.data;

    if (currentSortOrder === newSortOrder && currentSortField === data.field) {
      return dataArray;
    }

    setCurrentSortOrder(newSortOrder);
    setCurrentSortField(data.field);

    if (newSortOrder === 1) {
      if (data.field === "title") {
        return dataArray.sort((a, b) => a.title.props.children.localeCompare(b.title.props.children));
      } else if (data.field === "description") {
        return dataArray.sort((a, b) => a.description.props.title.localeCompare(b.description.props.title));
      } else if (data.field === "status") {
        return dataArray.sort((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
      } else if (data.field == "by") {
        return dataArray.sort((a, b) => a.by.props.children.props.children.localeCompare(b.by.props.children.props.children));
      }

    } else {
      if (data.field === "title") {
        return dataArray.reverse((a, b) => a.title.props.children.localeCompare(b.title.props.children));
      } else if (data.field === "description") {
        return dataArray.reverse((a, b) => a.description.props.title.localeCompare(b.description.props.title));
      } else if (data.field === "status") {
        return dataArray.reverse((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
      } else if (data.field == "by") {
        return dataArray.reverse((a, b) => a.by.props.children.props.children.localeCompare(b.by.props.children.props.children));
      }

    }

  };

  return (
    <>
      <SuiBox py={3}>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SuiTypography variant="h6">Teams List</SuiTypography>

            <div className="search-action-btn-wrap">
              <SuiBox pr={3} className="searchContainer" >
                <SuiInput
                  placeholder="Search here..."
                  size="small"
                  onChange={debounceHandleChange}
                  name="search"
                  id="search"
                />
                <IconButton
                  size="small"
                  color="inherit"
                  className={searchClassName}
                  onClick={clearSearch}
                >
                  <Icon className="text-white">
                    close
                  </Icon>
                </IconButton>
              </SuiBox>

              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => navigate("/teams/create")}
              >
                Create New Team
              </SuiButton>
            </div>

          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >

            <DataTable value={rows} sortField="title" sortOrder={1} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ padding: '10px', minWidth: '50rem' }}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
              <Column field="title" sortable sortFunction={onColumnSort} body={titleTemplate} header="TITLE"></Column>
              <Column field="description" sortable sortFunction={onColumnSort} body={bodyTemplate} header="DESCRIPTION"></Column>
              <Column field="status" sortable sortFunction={onColumnSort}  header="STATUS" ></Column>
              <Column field="apps" header="APPS" ></Column>
              <Column field="users" header="USERS" ></Column>
              <Column field="by" header="BY" ></Column>
              <Column field="action" header="ACTION" ></Column>
            </DataTable>

          </SuiBox>
        </Card>
      </SuiBox>
    </>
  );
}

export default Tables;
