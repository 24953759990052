import {
    Card,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    useTheme, 
  CircularProgress, 
  Icon,
  IconButton ,
  Tooltip,
  } from "@mui/material";
  import SuiBox from "components/SuiBox";
  import SuiButton from "components/SuiButton";
  import SuiInput from "components/SuiInput";
  import SuiTypography from "components/SuiTypography";
  import React, { useEffect, useState } from "react";
  import { useLocation, useNavigate , useHistory} from "react-router-dom";
  import { Formik } from "formik";
  import * as yup from "yup";
  import { apiServices } from "services/apiServices";
  import { toast } from "react-toastify";
  import { Box } from "@mui/system";
  import { ArrowDownward } from "@mui/icons-material";
  import { constants } from "constants";
  import SuiBadge from "components/SuiBadge";
  import moment from "moment";
  import Table from "examples/Tables/Table";


  function AppDataAPI(props) {   
    
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const [columns] = useState([
      { name: "id", align: "left" },
      { name: "platform", align: "left" },
      { name: "version", align: "center" }, 
      { name: "action", align: "center" },
    ]);


    const theme = useTheme({
      props: {
        MuiSelect: {
          IconComponent: ArrowDownward,
        },
      },
    });
    const { state } = useLocation();
    const [key, setKey] = useState(false);
    const [rows, setRows] = useState([]);

    const location = useLocation();  
    const pathname = location.pathname ;
    const app_name = pathname.replace("/apps/appname/", "") 
     
    // console.log(state.id);
    const inputData = {
      logo:"",
      title: "",
      description:"",
      status: "",
      type: "",
      platform: "",
    };
   
  
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/gif"];
  
    const formValidation = yup.object().shape({
      logo: yup
        .mixed()
        .nullable()
        .required("A file is required")
        .test(
          "size",
          "uploaded file size not acceptable",
          (inputData) => !inputData.logo || (inputData.logo && inputData.logo.size < 35000 * 1024)
        )
        .test(
          "format",
          "Only the following formats are accepted: .jpeg, .jpg and .gif",
          (inputData) => !inputData.logo || (inputData.logo && SUPPORTED_FORMATS.includes(inputData.logo.type))
        ),
      title: yup.string().min(1).max(25).required("Title is required"),
      description: yup
        .string()
        .min(1)
        .max(50)
        .required("Description is required"),
      platform: yup.array().min(1, "Atleast select one platform"),
    });

  useEffect(() => {
   getApp1();
  }, []);
 
  const getApp1 = async () => {
    try {     
     // const title1  = 'Twitter' ;
      const title1  = app_name;      
      var title = title1.replace(/_/g, ' ');
  
     //console.log(title);
      await apiServices
      .httpRequestNonAuth("GET", `apps/appname/${title}`, null)
      .then((res) => {
       // console.log(res);
         if(res !="NotFound"){
          if(res.status =="active"){ 
              const appId = res._id;
              getAppData(appId);
          }else{           
            createTableNoData(["App not found!!"]);  
          }

        } else{               
          createTableNoData("App not found!");         
        }
        
      }) 
 
    } catch (error) {      
     // console.log(error);
    }
  };
 
  const getAppData = async (appId) => {
    console.log("getAppData ");
    try {
      await apiServices
      .httpRequestNonAuth("GET", `apps/attachments/${appId}`, null)
      .then((res) => { 
        //console.log(res);
        createTableRows(res);
     })
  
    } catch (error) {
     // console.log(error);
    }
  };
  const getDataFromURL = (url) => {
    var fileName = url.slice(url.lastIndexOf("/") + 1);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };
  const createTableNoData = (data) => {
    let tableRows = data;
    setRows(tableRows);
  }
 
  const createTableRows = (rows) => {

    let tableRows = [];
     // console.log(rows1.length)
    if(rows.length >0){
/*
    // Declare a new array
    let rows = [];      
    // Declare an empty object
    let uniqueObject = {};
      
    // Loop for the array elements
    for (let i in rows1) {
        const objTitle = rows1[i]['platform'];
        // Use the title as the index
        uniqueObject[objTitle] = rows1[i];
    }
      
    // Loop to push unique object into array
    for (let i in uniqueObject) {
      rows.push(uniqueObject[i]);
    }
    
    */
     
    rows.map((value, i) => {
       
      tableRows.push({
        id: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            #{i + 1}
          </SuiTypography>
        ),
        platform: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {value.platform}
          </SuiTypography>
        ),        
        version: (
          <SuiBadge
            variant="gradient"
            badgeContent={`V_${value.version}`}
            color={"success"}
            size="xs"
            container
          />
        ),
        
       
        action: (
          <>
            <Tooltip title="Download file">
              <IconButton
                onClick={() =>
                  getDataFromURL(constants.apiUrl + value.attachment)
                }
              >
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  fontSize="small"
                >
                  download
                </Icon>
              </IconButton>
            </Tooltip>
           
          </>
        ),
      });
    });

      
  }else{

    tableRows.push({
      id: (
        <SuiTypography
          pl={2}
          variant="button"
          color="text"
          fontWeight="medium"
        >
          No App Found
        </SuiTypography>
      ),
      platform:'',        
      version: '',
      action:'',       
    });
  
   //rows
  //tableRows = '<TableRow><TableCell>No data to display</TableCell></TableRow>';

  }
    setRows(tableRows);
  };


    const navigate = useNavigate();
    const [formInput] = useState(inputData);
 
  
    function getStyles(name, personName, theme) {
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    }
  
    return (
      <>
        <SuiBox py={3}>
          <Card>
            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SuiTypography variant="h6"> Application Details</SuiTypography>
              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => navigate(-1)}
              >
                Back
              </SuiButton>
            </SuiBox>
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              
             <Table columns={columns} rows={rows} /> 
            </SuiBox>
          </Card>
        </SuiBox>
      </>
    );
  }
  
  export default AppDataAPI;
  