import {
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { apiServices } from "services/apiServices";
import { toast } from "react-toastify";
import SuiBadge from "components/SuiBadge";
import SuiAvatar from "components/SuiAvatar";
import burceMars from "assets/images/bruce-mars.jpg";
import { constants } from "constants";
import StorageService from "services/StorageService";
import axios from "axios";
import moment from "moment";

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

function View(props) {
  const [appsColumns] = useState([
    { name: "id", align: "left" },
    { name: "title", align: "left" },
    { name: "status", align: "center" },
    { name: "action", align: "center" },
  ]);
  const [usersColumns] = useState([
    { name: "id", align: "left" },
    { name: "name", align: "left" },
    { name: "email", align: "center" },
   /*  { name: "action", align: "center" }, */
  ]);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [team, setTeam] = useState([]);
  const [appsRows, setAppsRows] = useState([]);
  const [usersRows, setUsersRows] = useState([]);
  const [key, setKey] = useState(false);

  const [openSettings, setOpenSettings] = useState(false);
  const [platformData, setPlatformData] = useState([]);
  const [os, setOs] = useState([]);
  const [appDetails, setAppDetails] = useState({
    appId: "",
    title: "",
  });

  const [currentAppSortOrder, setCurrentAppSortOrder] = useState(1);
  const [currentAppSortField, setCurrentAppSortField] = useState("id");

  const [currentUserSortOrder, setCurrentUserSortOrder] = useState(1);
  const [currentUserSortField, setCurrentUserSortField] = useState("id");

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;

  useEffect(() => {
    getTeams(data);
  }, [key, state]);

  const createAppsTableRows = (rows) => {
    let tableRows = [];
    rows.map((value, i) => {
      tableRows.push({
        id: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            #{i + 1}
          </SuiTypography>
        ),
        title: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {value.app.title}
          </SuiTypography>
        ),
        status: (
          <SuiBadge
            variant="gradient"
            badgeContent={value.app.status}
            color={value.app.status === "active" ? "success" : "error"}
            size="xs"
            container
          />
        ),
        action: (
          <>
             <Platform
              data={{
                title: value.app.title,
                platform: value.app.platform,
                appId: value.app._id,
                logo: value.app.logo,
                os: value.app.minimumOs,
              }}
              index={i}
            />
          </>
        ),
      });
    });
    setAppsRows(tableRows);
  };

  const createUsersTableRows = (rows) => {
    let tableRows = [];
    rows.map((value, i) => {
      tableRows.push({
        id: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            #{i + 1}
          </SuiTypography>
        ),
        name: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {value.user.firstName}
          </SuiTypography>
        ),
        email: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            {value.user.email}
          </SuiTypography>
        ),
        /* action: (
          <>
            <Tooltip title="view">
              <IconButton
                data-id={value.user._id}
                onClick={() => console.log("ok2")}
              >
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  fontSize="small"
                >
                  visibility
                </Icon>
              </IconButton>
            </Tooltip>
          </>
        ), */
      });
    });
    setUsersRows(tableRows);
  };

  async function getTeams(team) {
    try {
      const teams = await apiServices.httpRequest(
        "GET",
        `team/${team.teamId}`,
        null
      );
      setTeam(teams);
      createAppsTableRows(teams.apps);
      createUsersTableRows(teams.users);
    } catch (error) {
      console.log(error);
    }
  }

  function Platform({ data, index }) {
    return (
      <>
        <IconButton onClick={(e) => handleOpenSettings(e, data)}>
          <Tooltip title="Click and check Compatibility with platforms">
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
            >
              visibility
            </Icon>
          </Tooltip>
        </IconButton>
      </>
    );
  }

  function viewPlatform(value, appData, os, _id) {
    navigate(`/projects/platform/${appData.appId}`, {
      state: {
        platform: value,
        appData: appData,
        os: os,
      },
    });
  }

  const handleOpenSettings = (event, data) => {
    setAppDetails({
      appId: data.appId,
      title: data.title,
      logo: data.logo,
    });
    setOs(data.os);
    setPlatformData(data.platform);
    setOpenSettings(event.currentTarget);
  };

  const handleCloseSettings = () => setOpenSettings(false);

  const renderSettings = () => (
    <Menu
      anchorEl={openSettings}
      id="account-menu"
      open={Boolean(openSettings)}
      onClose={handleCloseSettings}
      onClick={handleCloseSettings}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }} 
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {platformData.map((res, i) => {
        return (
          <MenuItem key={i} onClick={() => viewPlatform(res, appDetails, os[i][res])}>
            {res}
          </MenuItem>
        );
      })}
    </Menu>
  );

  const onAppsColumnSort = (data) => {
    var newSortOrder = data.order;
    var dataArray = data.data;

    if (currentAppSortOrder === newSortOrder && currentAppSortField === data.field) {
      return dataArray;
    }

    setCurrentAppSortOrder(newSortOrder);
    setCurrentAppSortField(data.field);

    if (newSortOrder === 1) {

      if (data.field === "id") {
        return dataArray.sort((a, b) => a.id.props.children[1] - b.id.props.children[1]);
      } else if (data.field === "title") {
        return dataArray.sort((a, b) => a.title.props.children.localeCompare(b.title.props.children));
      } else if (data.field === "status") {
        return dataArray.sort((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
      }

    } else {

      if (data.field === "id") {
        return dataArray.reverse((a, b) => a.id.props.children[1].localeCompare(b.id.props.children[1]));
      } else if (data.field === "title") {
        return dataArray.reverse((a, b) => a.title.props.children.localeCompare(b.title.props.children));
      } else if (data.field === "status") {
        return dataArray.reverse((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
      }

    }

  }

  const onUserColumnSort = (data) => {
    var newSortOrder = data.order;
    var dataArray = data.data;

    if (currentUserSortOrder === newSortOrder && currentUserSortField === data.field) {
      return dataArray;
    }

    setCurrentUserSortOrder(newSortOrder);
    setCurrentUserSortField(data.field);

    if (newSortOrder === 1) {

      if (data.field === "id") {
        return dataArray.sort((a, b) => a.id.props.children[1] - b.id.props.children[1]);
      } else if (data.field === "name") {
        return dataArray.sort((a, b) => a.name.props.children.localeCompare(b.name.props.children));
      } else if (data.field === "email") {
        return dataArray.sort((a, b) => a.email.props.children.localeCompare(b.email.props.children));
      }

    } else {

      if (data.field === "id") {
        return dataArray.reverse((a, b) => a.id.props.children[1].localeCompare(b.id.props.children[1]));
      } else if (data.field === "name") {
        return dataArray.reverse((a, b) => a.name.props.children.localeCompare(b.name.props.children));
      } else if (data.field === "email") {
        return dataArray.reverse((a, b) => a.email.props.children.localeCompare(b.email.props.children));
      }

    }

  };

  return (
    <>
      <SuiBox py={3}>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SuiBox>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <SuiBox
                    component="img"
                    src={constants.apiUrl + "logo/teams.png"}
                    alt="Team Logo"
                    width="8rem"
                    shadow="sm"
                  />
                </Grid>
                <Grid item>
                  <SuiBox height="100%" mt={0.5} lineHeight={1}>
                    <SuiTypography variant="h5" fontWeight="medium">
                      {team.title}
                    </SuiTypography>
                    <SuiTypography
                      variant="button"
                      color="text"
                      fontWeight="medium"
                    >
                      {team.description}
                    </SuiTypography>
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
            <SuiBox>
              <SuiButton
                sx={{ mr: 1 }}
                variant="gradient"
                color="dark"
                size="small"
                onClick={() =>
                  navigate("/teams/edit", {
                    state: {
                      data,
                    },
                  })
                }
              >
                Edit
              </SuiButton>
              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => navigate(-1)}
              >
                Back
              </SuiButton>
            </SuiBox>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <SuiBox pb={3} px={3}></SuiBox>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs>
                  <SuiBox pb={3} px={6}>
                    <SuiBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                    >
                      <SuiTypography variant="h6">Apps</SuiTypography>
                    </SuiBox>

                    <DataTable value={appsRows} sortField="id" sortOrder={1} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ padding: '10px'}}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
                      <Column field="id" sortable sortFunction={onAppsColumnSort} header="ID"></Column>
                      <Column field="title" sortable sortFunction={onAppsColumnSort} header="TITLE"></Column>
                      <Column field="status" sortable sortFunction={onAppsColumnSort} header="STATUS" ></Column>
                      <Column field="action" header="ACTION" ></Column>
                    </DataTable>

                    {renderSettings()}
                  </SuiBox>
                </Grid>
                <Grid item xs>
                  <SuiBox pb={3} px={6}>
                    <SuiBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                    >
                      <SuiTypography variant="h6">Users</SuiTypography>
                    </SuiBox>

                    <DataTable value={usersRows} sortField="id" sortOrder={1} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ padding: '10px', minWidth: '50rem' }}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
                      <Column field="id" sortable sortFunction={onUserColumnSort} header="ID"></Column>
                      <Column field="name" sortable sortFunction={onUserColumnSort} header="NAME"></Column>
                      <Column field="email" sortable sortFunction={onUserColumnSort} header="EMAIL" ></Column>
                    </DataTable>

                    {/* <Table columns={usersColumns} rows={usersRows} /> */}
                  </SuiBox>
                </Grid>
              </Grid>
            </SuiBox>
          </SuiBox>
        </Card>
      </SuiBox>
    </>
  );
}

export default View;
