// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";

// Data
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { apiServices } from "services/apiServices";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { loggedIn, userProfile } from "redux/reduxSlice/UserSlice";
// react-router-dom components
import { useLocation, NavLink, useNavigate, useParams } from "react-router-dom";

/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiProgress from "components/SuiProgress";

// Images
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import SuiBadge from "components/SuiBadge";
import {
  Avatar,
  capitalize,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Tooltip,
} from "@mui/material";

import swal from "sweetalert";
import { constants } from "constants";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";

import { helper } from "services/helper";

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Tables() {
  const navigate = useNavigate();
  const [columns] = useState([
    { name: "apps", align: "left" },
    { name: "description", align: "left" },
    { name: "status", align: "center" },
    { name: "type", align: "left" },
    // { name: "by", align: "left" },
    { name: "action", align: "center" },
    { name: "clipboard", align: "center" },
  ]);
  const [rows, setRows] = useState([]);
  const [key, setKey] = useState(false);

  const [openSettings, setOpenSettings] = useState(false);
  const [platformData, setPlatformData] = useState([]);
  const [os, setOs] = useState([]);
  const [appDetails, setAppDetails] = useState({
    appId: "",
    title: "",
  });
  const [currentSortOrder, setCurrentSortOrder] = useState(1);
  const [currentSortField, setCurrentSortField] = useState("title");

  const location = useLocation();
  const userProfile = useSelector((state) => state.isLogin.profile);

  const [open, setOpen] = useState(false);
  const [searchClassName, setSearchClassName] = useState("clearBtn hide");

  const debounceHandleChange = helper.debounce((e) => handleChange(e));

  const handleChange = async (e) => {
    const { value } = e.target
    setSearchClassName((value.length > 0) ? 'clearBtn' : 'clearBtn hide');
    getApps(value);
  }

  const clearSearch = () => {
    const searchInput = document.getElementById('search');
    searchInput.value = '';
    setSearchClassName("clearBtn hide");
    getApps();
  }

  useEffect(() => {
    getApps();
  }, [key]);

  const appDelete = async (e) => {
    const _id = e.currentTarget.dataset.id;
    swal({
      closeOnClickOutside: false,
      title: "Are you sure?",
      text: "But you will still be able to retrieve this file.",
      icon: "error",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes, I am sure",
        },
      },
      dangerMode: true,
    }).then(async (isConfirm) => {
      if (isConfirm) {
        await apiServices
          .httpRequest("DELETE", `apps/${_id}`, null)
          .then((res) => {
            setKey((prev) => !prev);
            swal("Deleted!", res.message, "success");
          })
          .catch((error) => {
            swal("Deleted!", error, "error");
          });
      } else {
        swal("Cancelled", "Your App is safe :)", "error");
        e.preventDefault();
      }
    });
  };

  const appEdit = async (e) => {
    const _id = e.currentTarget.dataset.id;
    await apiServices
      .httpRequest("GET", `apps/edit/${_id}`, null)
      .then((res) => {
        console.log(res);
      });
  };

  const handleOpenSettings = (event, data) => {
    setAppDetails({
      appId: data.appId,
      title: data.title,
      logo: data.logo,
    });
    setOs(data.os);
    setPlatformData(data.platform);
    setOpenSettings(event.currentTarget);
  };
  const handleCloseSettings = () => setOpenSettings(false);

  const renderSettings = () => (
    <Menu
      anchorEl={openSettings}
      id="account-menu"
      open={Boolean(openSettings)}
      onClose={handleCloseSettings}
      onClick={handleCloseSettings}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {platformData.map((res, i) => {
        return (
          <MenuItem
            key={i}
            onClick={() => viewPlatform(res, appDetails, os[i][res])}
          >
            {res}
          </MenuItem>
        );
      })}
    </Menu>
  );

  function Platform({ data, index }) {
    return (
      <>
        <IconButton onClick={(e) => handleOpenSettings(e, data)}>
          <Tooltip title="Click and check Compatibility with platforms">
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
            >
              visibility
            </Icon>
          </Tooltip>
        </IconButton>
      </>
    );
  }

  function viewPlatform(value, appData, os, _id) {
    navigate(`/projects/platform/${appData.appId}`, {
      state: {
        platform: value,
        appData: appData,
        os: os,
      },
    });
  }

  function viewApp(
    value,
    logo,
    title,
    description,
    status,
    type,
    platform,
    minimumOs
  ) {

    navigate(`/projects/view/${value}`, {
      state: {
        id: value,
        logo: logo,
        title: title,
        description: description,
        status: status,
        type: type,
        platform: platform,
        minimumOs: minimumOs,
      },
    });
  }

  function editApp(
    value,
    logo,
    title,
    description,
    status,
    type,
    platform,
    minimumOs
  ) {
    navigate(`/projects/edit/${value}`, {
      state: {
        id: value,
        logo: logo,
        title: title,
        description: description,
        status: status,
        type: type,
        platform: platform,
        minimumOs: minimumOs,
      },
    });
  }

  const handleClick = (_id) => {
    setOpen(true);
    navigator.clipboard.writeText(`${constants.appUrl}user/home/${_id}`);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const createTableRows = (rows) => {
    let tableRows = [];
    rows.map((value, i) => {

      let logoval = "";
      if (value.logo === "") {
        let logo_val = "bishop-placeholder-logo.jpeg"
        logoval = `${constants.appUrl}${logo_val}`;
      } else {
        logoval = `${constants.apiUrl}${value.logo}`
      }
      //console.log(value.logo)
      tableRows.push({
        id: value._id,
        apps: [`${logoval}`, value.title],
        description: (
          <Tooltip title={value.description}>
            <SuiTypography variant="button" color="text" fontWeight="medium">
              {value.description}
            </SuiTypography>
          </Tooltip>
        ),
        status: (
          <SuiBadge
            variant="gradient"
            badgeContent={value.status}
            color={value.status === "active" ? "success" : "error"}
            size="xs"
            container
          />
        ),
        type: (
          <SuiBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <SuiTypography variant="button" fontWeight="medium">
              {value.type}
            </SuiTypography>
          </SuiBox>
        ),
        // by: (
        //   <SuiBox
        //     display="flex"
        //     flexDirection="column"
        //     alignItems="flex-start"
        //     justifyContent="center"
        //   >
        //     <SuiTypography variant="button" fontWeight="medium">
        //       {value.createdBy["firstName"]}
        //     </SuiTypography>
        //     <SuiTypography variant="caption" color="text">
        //       {moment(value.created).calendar()}
        //     </SuiTypography>
        //   </SuiBox>
        // ),
        platform: value.platform,
        minimumOs: value.minimumOs,
        action: (
          <>
            {/* <Platform
              data={{
                title: value.title,
                platform: value.platform,
                appId: value._id,
                logo: value.logo,
                os: value.minimumOs,
              }}
              index={i}
            /> */}

            <IconButton
              onClick={() =>
                viewApp(
                  value._id,
                  value.logo,
                  value.title,
                  value.description,
                  value.status,
                  value.type,
                  value.platform,
                  value.minimumOs
                )
              }
            >
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
              >
                visibility
              </Icon>
            </IconButton>

            <IconButton
              onClick={() =>
                editApp(
                  value._id,
                  value.logo,
                  value.title,
                  value.description,
                  value.status,
                  value.type,
                  value.platform,
                  value.minimumOs
                )
              }
            >
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
              >
                edit
              </Icon>
            </IconButton>
            <IconButton data-id={value._id} onClick={appDelete}>
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
              >
                delete
              </Icon>
            </IconButton>
          </>
        ),
        clipboard: (
          <>
            <IconButton onClick={() => handleClick(value._id)}>
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
              >
                copy
              </Icon>
            </IconButton>
          </>
        ),
      });
    });
    setRows(tableRows);
  };

  async function getApps(searchTerm = null) {
    try {

      let search = '';
      if (searchTerm) {
        search = "?searchTerm=" + searchTerm;
      }

      if (userProfile.userType === "user") {
        const userId = userProfile.userId;
        const apps = await apiServices.httpRequest(
          "GET",
          `team/user/${userId}`,
          null
        );

        createTableRows(apps);
      } else {
        const apps = await apiServices.httpRequest("GET", "apps" + search, null);
        createTableRows(apps);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const appsTemplate = (rowData) => {

    let logoval = "";
    if (rowData.apps[0] === "") {
      let logo_val = "bishop-placeholder-logo.jpeg"
      logoval = `${constants.appUrl}${logo_val}`;
    } else {
      //logoval = `${constants.apiUrl}${rowData.apps[0]}`
      logoval = `${rowData.apps[0]}`
    }

    return (
      <>
        <div className="textLink"
          onClick={() =>
            viewApp(
              rowData.id,
              rowData.apps[0],
              rowData.apps[1],
              rowData.description.props.title,
              rowData.status.props.badgeContent,
              rowData.type.props.children.props.children,
              rowData.platform,
              rowData.minimumOs
            )
          }
        >
          <div className="MuiBox-root css-go6tax" style={{ marginRight: "16px", display: "inline-flex" }} >
            <SuiAvatar src={logoval} alt={rowData.apps[1]} size="sm" variant="rounded" />
          </div>
          <SuiTypography variant="button" color="text" fontWeight="medium" verticalAlign="super" >
            {rowData.apps[1]}
          </SuiTypography>

        </div>

      </>
    )

  }

  const descriptionTemplate = (rowData) => {

    return (
      <>
        <div className="textLink"
          onClick={() =>
            viewApp(
              rowData.id,
              rowData.apps[0],
              rowData.apps[1],
              rowData.description.props.title,
              rowData.status.props.badgeContent,
              rowData.type.props.children.props.children,
              rowData.platform,
              rowData.minimumOs
            )
          }
        >
          <Tooltip title={rowData.description.props.title}>
            <SuiTypography variant="button" color="text" fontWeight="medium" >
              {rowData.description.props.title}
            </SuiTypography>
          </Tooltip>
        </div>
      </>
    )

  }

  const onColumnClickSort = (data) => {

    var newSortOrder = data.order;
    var dataArray = data.data;

    if (currentSortOrder === newSortOrder && currentSortField === data.field) {
      return dataArray;
    }

    setCurrentSortOrder(newSortOrder);
    setCurrentSortField(data.field);

    if (data.field == "apps") {

      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.apps[1].localeCompare(b.apps[1]));
      } else {
        return dataArray.reverse((a, b) => a.apps[1].localeCompare(b.apps[1]));
      }

    } else if (data.field == "description") {
      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.description.props.title.localeCompare(b.description.props.title));
      } else {
        return dataArray.reverse((a, b) => a.description.props.title.localeCompare(b.description.props.title));
      }
    } else if (data.field == "type") {
      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.type.props.children.props.children.localeCompare(b.type.props.children.props.children));
      } else {
        return dataArray.reverse((a, b) => a.type.props.children.props.children.localeCompare(b.type.props.children.props.children));
      }
    } else if (data.field == "status") {
      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
      } else {
        return dataArray.reverse((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
      }
    }

    return dataArray;
  };

  return (
    <>
      <SuiBox py={3}>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SuiTypography variant="h6">Projects List</SuiTypography>

            <div className="search-action-btn-wrap">
              <SuiBox pr={3} className="searchContainer" >
                <SuiInput
                  placeholder="Search here..."
                  size="small"
                  onChange={debounceHandleChange}
                  name="search"
                  id="search"
                />
                <IconButton
                  size="small"
                  color="inherit"
                  className={searchClassName}
                  onClick={clearSearch}
                >
                  <Icon className="text-white">
                    close
                  </Icon>
                </IconButton>
              </SuiBox>

              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => navigate("/projects/create")}
              >
                Create New Project
              </SuiButton>
            </div>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >

            <DataTable value={rows} sortField="apps" sortOrder={1} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ padding: '10px', minWidth: '50rem' }}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
              <Column field="apps" sortable sortFunction={onColumnClickSort} body={appsTemplate} header="APPS"></Column>
              <Column field="description" sortable sortFunction={onColumnClickSort} body={descriptionTemplate} header="DESCRIPTION"></Column>
              <Column field="status" sortable sortFunction={onColumnClickSort} header="STATUS"></Column>
              <Column field="type" sortable sortFunction={onColumnClickSort} header="TYPE"></Column>
              <Column field="action" header="ACTION"></Column>
              <Column field="clipboard" header="CLIPBOARD"></Column>
            </DataTable>

            {renderSettings()}
          </SuiBox>
        </Card>
      </SuiBox>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Copied to clipboard
        </Alert>
      </Snackbar>
    </>
  );
}

export default Tables;
