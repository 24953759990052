import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";

import { FormHelperText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { apiServices } from "../../../services/apiServices";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { loggedIn, userProfile } from "redux/reduxSlice/UserSlice";
import swal from "sweetalert";

function SignIn() {
  const inputData = {
    email: "",
    password: "",
  };

  const formValidation = yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(true);
  const [auth] = useState(useSelector((state) => state.isLogin.value));
  const [formInput] = useState(inputData);
  const dispatch = useDispatch();
  let { token, email, oldEmail=null } = useParams();

  useEffect(() => {
    if (auth) {
      navigate("/dashboard");
    }
    if (token) {
      activateAccount({ email: email, token: token, oldEmail: oldEmail });
    }
  }, [auth, token]);

  const activateAccount = async (request) => {
    await apiServices
      .activateAccount(request)
      .then((res) => {
        swal(res.message, "", "success");
      })
      .catch((error) => {
        swal(error, "", "error");
      });
  };
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    delete values.confirmPassword;
    await apiServices
      .loginRequest(values)
      .then((res) => {
        resetForm({ values: "" });
        toast.success("Login Successful");
        dispatch(loggedIn());
        dispatch(userProfile());
        navigate("../dashboard", { replace: true });
      })
      .catch((error) => {
        if (
          error.flag === "mailVerification"
        ) {
          swal({
            closeOnClickOutside: false,
            title: "Account not active !",
            text: error.message,
            icon: "error",
            buttons: {
              cancel: "Cancel",
              catch: {
                text: "Confirm Email",
                value: values.email,
              },
            },
            dangerMode: true,
          }).then((verify) => {
            let input = document.createElement("input");
            input.value = verify;
            input.type = "email";
            input.className = "swal-content__input";
            input.disabled = true;
            if (verify) {
              swal({
                closeOnClickOutside: false,
                title: "Please confirm email !",
                content: input,
                buttons: {
                  cancel: "Cancel",
                  confirm: {
                    text: "Resend",
                    value: verify,
                  },
                },
              }).then(async (value) => {
                await apiServices
                  .verifyEmail(value)
                  .then((res) => {
                    swal(res.message, "", "success");
                  })
                  .catch((error) => {
                    swal(error, "", "error");
                  });
              });
              // swal("We sent you an activation link. Check your email and click on the link to verify.", {
              //   icon: "success",
              // });
            }
          });
        } else {
          swal('Error',error.message,'error')
        }
      });
    setSubmitting(false);
  };

  return (
    <BasicLayout
      title="Welcome!"
      description="Use these awesome forms to login or create new account."
      image={curved6}
    >
      <Card>
        <SuiBox p={3} mb={1} textAlign="center">
          <SuiTypography variant="h5" fontWeight="medium">
            Sign in with
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={2}>
          {/* <Socials /> */}
        </SuiBox>
        {/* <Separator /> */}
        <SuiBox pt={2} pb={3} px={3}>
          <Formik
            initialValues={formInput}
            validationSchema={formValidation}
            onSubmit={(values, { setSubmitting, resetForm }) =>
              handleSubmit(values, setSubmitting, resetForm)
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <SuiBox component="form" role="form" onSubmit={handleSubmit}>
                <SuiBox mb={2}>
                  <SuiInput
                    error={touched.email && errors.email && true}
                    success={touched.email && !errors.email && true}
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={values.email || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText error={true}>
                    {touched.email && errors.email && errors.email}
                  </FormHelperText>
                </SuiBox>
                <SuiBox mb={2}>
                  <SuiInput
                    error={touched.password && errors.password && true}
                    success={touched.password && !errors.password && true}
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={values.password || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <SuiTypography
                    component={Link}
                    to="/authentication/forgot-password"
                    variant="button"
                    color="dark"
                    fontWeight="bold"
                    textGradient
                  >
                    &nbsp;&nbsp;Forgot Password?
                  </SuiTypography>

                  <FormHelperText error={true}>
                    {touched.password && errors.password && errors.password}
                  </FormHelperText>
                </SuiBox>
                <SuiBox display="flex" alignItems="center">
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                  >
                    &nbsp;&nbsp;Remember me
                  </SuiTypography>
                </SuiBox>
                <SuiBox mt={4} mb={1}>
                  <SuiButton
                    variant="gradient"
                    color="dark"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    sign in
                  </SuiButton>
                </SuiBox>
                <SuiBox mt={3} textAlign="center">
                  <SuiTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    textTransform="none"
                  >
                    Don't have an account?&nbsp;
                    <SuiTypography
                      component={Link}
                      to="/authentication/sign-up"
                      variant="button"
                      color="dark"
                      fontWeight="bold"
                      textGradient
                    >
                      Sign up
                    </SuiTypography>
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            )}
          </Formik>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
