import { constants } from "../constants";
import StorageService from "./StorageService";

export const apiServices = {
  signupRequest,
  httpRequest,
  loginRequest,
  verifyEmail,
  activateAccount,
  tokenSession,
  httpRequestNonAuth,
  forgotPasswordRequest,
  verifyResetHashRequest,
  resetPasswordRequest
};

async function signupRequest(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return await fetch(`${constants.apiUrl}signup`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function loginRequest(data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return await fetch(`${constants.apiUrl}login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      StorageService.setLogin(user);
      return user;
    });
}

async function verifyEmail(email) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    body: null,
  };
  return await fetch(`${constants.apiUrl}verify-email/${email}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function activateAccount(userData) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
  };
  return await fetch(`${constants.apiUrl}activate-account`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function httpRequest(methodType, url, bodyData, contentType = null) {
  const requestOptions = {
    method: methodType,
    headers:
      contentType !== null
        ? {
          Accept: "application/form-data",
          Authorization: `Bearer ${StorageService.getLogin().token}`,
        }
        : {
          "Content-Type": "application/json",
          Authorization: `Bearer ${StorageService.getLogin().token}`,
        },
    body: bodyData,
  };
  return await fetch(`${constants.apiUrl + url}`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

async function httpRequestNonAuth(
  methodType,
  url,
  bodyData,
  contentType = null
) {
  const requestOptions = {
    method: methodType,
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyData,
  };
  return await fetch(`${constants.apiUrl + url}`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

async function tokenSession() {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${StorageService.getLogin().token}`,
    },
    body: null,
  };
  return await fetch(`${constants.apiUrl + "profile"}`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 404) {
        response.statusText = "not found";
      }
      if (data && data.flag) {
        return Promise.reject(data);
      }
      const error = (data && data) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

async function forgotPasswordRequest(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return await fetch(`${constants.apiUrl}forgotPassword`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

async function verifyResetHashRequest(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return await fetch(`${constants.apiUrl}verifyResetHash`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

async function resetPasswordRequest(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return await fetch(`${constants.apiUrl}resetPassword`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}