import { createSlice } from "@reduxjs/toolkit";
import StorageService from "services/StorageService";

export const UserSlice = createSlice({
  name: "isLogin",
  initialState: {
    value: StorageService.getLogin() !== null ? true : false,
    profile: StorageService.getLogin() !== null ? StorageService.getLogin().profile : '',
    accessToken: StorageService.getLogin() !== null ? StorageService.getLogin().token : ''
  },
  reducers: {
    loggedIn: (state) => {
      state.value = StorageService.getLogin() !== null ? true : false;
    },
    userProfile: (state, action) => {
      state.profile = StorageService.getLogin() !== null ? StorageService.getLogin().profile : state;
    },
    accessToken: (state, action) => {
      state.accessToken = StorageService.getLogin() !== null ? StorageService.getLogin().token : state;
    },
    loggedOut: (state) => {
      state.value = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loggedIn, userProfile, accessToken, loggedOut } = UserSlice.actions;

export default UserSlice.reducer;
