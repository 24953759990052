import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loggedIn } from "../redux/reduxSlice/UserSlice";

const PrivateWrapper = ({ auth: { isAuthenticated }, children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loggedIn());
  },[dispatch])
  const isLogin = useSelector((state) => state.isLogin.value);
  isAuthenticated = isLogin;
  return isAuthenticated ? children : <Navigate to="/authentication/sign-in" />;
};

export default PrivateWrapper;
