import { useState } from "react";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";

import { FormHelperText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { errorMessages } from "../../../services/formValidation";
import { apiServices } from "../../../services/apiServices";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { loggedIn, userProfile } from "redux/reduxSlice/UserSlice";
import swal from "sweetalert";

function ResetPassword() {

    let { hash } = useParams();

    const [userId, setUserId] = useState();

    /** check if hash is valid */

    const verifyHash = async (hash) => {
        await apiServices
            .verifyResetHashRequest({hash: hash})
            .then((res) => {
                setUserId(res.data)
            })
            .catch((error) => {
                swal('Error', error.message, 'error')
                navigate("/authentication/login", { replace: true });
            });
    }

    verifyHash(hash);

    const inputData = {
        password: "",
        confirmPassword: "",
    };

    const formValidation = yup.object().shape({
        password: yup.string().min(6, errorMessages["passwordMinLength"]).max(20, errorMessages["passwordMaxLength"]).required(errorMessages["password"]),
        confirmPassword: yup.string().oneOf([yup.ref("password"), null], errorMessages["confirmPasswordMatch"]).required(errorMessages["confirmPassword"]),
    });

    const navigate = useNavigate();

    const [formInput] = useState(inputData);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        delete values.confirmPassword;
        const user = { userId: userId, hash: hash}
        const input = {...values, ...user}

        await apiServices
            .resetPasswordRequest(input)
            .then((res) => {
                resetForm({ values: "" });
                toast.success("Password updated successfully");
                navigate("/authentication/login", { replace: true });
            })
            .catch((error) => {
                swal('Error', error.message, 'error')
            });
        setSubmitting(false);
    };

    return (
        <BasicLayout
            title="Welcome!"
            description="Use these awesome forms to login or create new account."
            image={curved6}
        >
            <Card>
                <SuiBox p={3} mb={1} textAlign="center">
                    <SuiTypography variant="h5" fontWeight="medium">
                        Reset Password
                    </SuiTypography>
                </SuiBox>
                <SuiBox mb={2}>
                    {/* <Socials /> */}
                </SuiBox>
                {/* <Separator /> */}
                <SuiBox pt={2} pb={3} px={3}>
                    <Formik
                        initialValues={formInput}
                        validationSchema={formValidation}
                        onSubmit={(values, { setSubmitting, resetForm }) =>
                            handleSubmit(values, setSubmitting, resetForm)
                        }
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <SuiBox component="form" role="form" onSubmit={handleSubmit}>
                                <SuiBox mb={2}>
                                    <SuiInput
                                        error={touched.password && errors.password && true}
                                        success={touched.password && !errors.password && true}
                                        type="password"
                                        placeholder="New Password"
                                        name="password"
                                        value={values.password || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <FormHelperText error={true}>
                                        {touched.password && errors.password && errors.password}
                                    </FormHelperText>
                                </SuiBox>

                                <SuiBox mb={2}>
                                    <SuiInput
                                        error={touched.confirmPassword && errors.confirmPassword && true}
                                        success={touched.confirmPassword && !errors.confirmPassword && true}
                                        type="password"
                                        placeholder="Confirm New Password"
                                        name="confirmPassword"
                                        value={values.confirmPassword || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <FormHelperText error={true}>
                                        {touched.confirmPassword && errors.confirmPassword && errors.confirmPassword}
                                    </FormHelperText>
                                </SuiBox>

                                <SuiBox mt={4} mb={1}>
                                    <SuiButton
                                        variant="gradient"
                                        color="dark"
                                        fullWidth
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        Reset Password
                                    </SuiButton>
                                </SuiBox>

                            </SuiBox>
                        )}
                    </Formik>
                </SuiBox>
            </Card>
        </BasicLayout>
    );
}

export default ResetPassword;
