export const errorMessages = {
  firstName: `First name is required`,
  firstNameMax: `First name must be at most 25 characters`,
  lastName: `Last name is required`,
  lastNameMax: `Last name must be at most 25 characters`,
  email: `Email is required`,
  emailFormat: `Please ingress a valid email address`,
  password: `Password is required`,
  passwordMinLength: `Password must be longer than 6 characters`,
  passwordMaxLength: `Password must shorter than 20 characters`,
  passwordWithLowercase: `Password must contain at least one lowercase`,
  passwordWithCapitalLetter: `Password must contain at least one capital letter`,
  passwordWithNumber: `Password must contain at least one number`,
  confirmPassword: `Password confirmation is required`,
  confirmPasswordMatch: `Password does not match confirmation password`,
};

const lowercase = new RegExp("(?=.*[a-z])");
const capital = new RegExp("(?=.*[A-Z])");
const number = new RegExp("(?=.*[0-9])");
// const strong = new RegExp("^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

export const formValidation = {
  fieldRequired,
  emailFormat,
  passwordFormat,
  passwordMatch,
  isValidate,
  sendRequestToServer,
};

function fieldRequired(input) {
  const { name, value, maxLength, minLenght } = input;
  if (value === "") {
    return responseHandler(input, false);
  } else {
    return responseHandler(input, true);
  }
}

function emailFormat(input) {
  const { name, value, maxLength, minLenght } = input;
  if (!checkEmail(value)) {
    return responseHandler(input, false);
  } else {
    return responseHandler(input, true);
  }
}

function checkEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function passwordFormat(input) {
  const { name, value, maxLength, minLength } = input;
  if (value.length < minLength) {
    input.name = "passwordMinLength";
    return responseHandler(input, false);
  } else if (value.length >= 20) {
    input.name = "passwordMaxLength";
    return responseHandler(input, false);
  } else if (!lowercase.test(value)) {
    input.name = "passwordWithLowercase";
    return responseHandler(input, false);
  } else if (!capital.test(value)) {
    input.name = "passwordWithCapitalLetter";
    return responseHandler(input, false);
  } else if (!number.test(value)) {
    input.name = "passwordWithNumber";
    return responseHandler(input, false);
  } else {
    return responseHandler(input, true);
  }
}

function passwordMatch(input) {
  const { name, value, password, confirmPassword } = input;
  if (confirmPassword !== password) {
    input.name = "confirmPasswordMatch";
    return responseHandler(input, false);
  } else {
    return responseHandler(input, true);
  }
}

function responseHandler(response, check) {
  return {
    ...response,
    errorMessage: check ? "" : errorMessages[response.name],
    error: !check,
    success: check,
  };
}

function isValidate(response) {
  var data = [];
  for (const key in response) {
    if (Object.hasOwnProperty.call(response, key)) {
      const element = response[key];
      if (element === "") {
        data[key] = responseHandler({ name: key, value: element }, false);
      } else {
        data[key] = responseHandler({ name: key, value: element }, true);
      }
    }
  }
  return data;
}

function sendRequestToServer(fields) {
  var response = false;
  for (const key in fields) {
    if (Object.hasOwnProperty.call(fields, key)) {
      const element = fields[key];
      console.log(element);
    }
  }
}
