// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Data
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { useNavigate } from "react-router-dom";
import { apiServices } from "services/apiServices";
import { useEffect, useState } from "react";
import { helper } from "services/helper";

/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";

import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";

import team2 from "assets/images/default-user.png";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import swal from "sweetalert";
import { withStyles } from "@mui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

function Tables() {
  const navigate = useNavigate();
  const [columns] = useState([
    { name: "user", align: "left" },
    { name: "role", align: "center" },
    { name: "status", align: "center" },
    { name: "action", align: "center" },
  ]);
  const [rows, setRows] = useState([]);
  const [key, setKey] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [currentSortOrder, setCurrentSortOrder] = useState(1);
  const [currentSortField, setCurrentSortField] = useState("user");

  const action = <></>;

  const [searchClassName, setSearchClassName] = useState("clearBtn hide");

  const debounceHandleChange = helper.debounce((e) => handleChange(e));

  const handleChange = async (e) => {
    const { value } = e.target
    setSearchClassName((value.length > 0) ? 'clearBtn' : 'clearBtn hide');
    getUsers(value);
  }

  const clearSearch = () => {
    const searchInput = document.getElementById('search');
    searchInput.value = '';
    setSearchClassName('clearBtn hide');
    getUsers();
  }

  useEffect(() => {
    if (!key && usersList.length === 0) {
      getUsers();
    }
    if (usersList.length !== 0) {
      createTableRows(usersList);
    }
  }, [key]);

  function Status({ status, index }) {
    return (
      <SuiBox display="flex" alignItems="center">
        <FormControl>
          <Select
            id="mui-select"
            sx={{ borderColor: status === "active" ? "green" : "error.main" }}
            name="status"
            value={status}
            onChange={(e) => setStatus(e, index)}
            IconComponent={ExpandMoreIcon}
          >
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"inactive"}>Inactive</MenuItem>
            <MenuItem value={"pending"}>Pending</MenuItem>
          </Select>
        </FormControl>
      </SuiBox>
    );
  }

  function Author({ image, name, email }) {
    return (
      <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <SuiBox mr={2}>
          <SuiAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SuiBox>
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="button" fontWeight="medium">
            {name}
          </SuiTypography>
          <SuiTypography variant="caption" color="secondary" textTransform="none">
            {email}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    );
  }

  const createTableRows = (rows) => {
    let tableRows = [];
    rows.map((value, i) => {
      let badgeColour = (value.userType == "user") ? "success" : "info";
      tableRows.push({
        user: (
          <Author
            image={team2}
            name={`${value.firstName} ${value.lastName}`}
            email={value.email}
          />
        ),
        role: (
          <SuiBadge
            variant="gradient"
            badgeContent={value.userType}
            color={badgeColour}
            size="xs"
            container
          />
        ),
        status: <Status status={value.status} index={i} />,
        action: (
          <>
            <IconButton
              onClick={() =>
                navigate("/users/edit", {
                  state: {
                    data: {
                      userId: value._id,
                      email: value.email,
                      firstName: value.firstName,
                      lastName: value.lastName,
                      status: value.status,
                      role: value.userType,
                    },
                  },
                })
              }
            >
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
              >
                edit
              </Icon>
            </IconButton>
            <IconButton onClick={(e) => deleteUserById(e, i)}>
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
              >
                delete
              </Icon>
            </IconButton>
          </>
        ),
      });
    });
    setRows(tableRows);
  };

  async function getUsers(searchTerm = null) {
    try {
      let search = '';
      if (searchTerm) {
        search = "?searchTerm=" + searchTerm;
      }
      const users = await apiServices.httpRequest("GET", "admin/users" + search, null);
      createTableRows(users);
      setUsersList(users);
      setKey(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function setStatus(e, i) {
    const { _id } = usersList[i];
    await apiServices
      .httpRequest(
        "PUT",
        `user/${_id}`,
        JSON.stringify({ status: e.target.value })
      )
      .then((res) => {
        usersList[i].status = e.target.value;
        setUsersList(usersList);
        setKey((prev) => !prev);
        swal("Status Changed", res.message, "success");
      })
      .catch((err) => console.log(err));
  }

  function deleteUserById(e, i) {
    const { _id } = usersList[i];
    swal({
      closeOnClickOutside: false,
      title: "Are you sure?",
      text: "But you will still be able to retrieve this user.",
      icon: "error",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes, I am sure",
        },
      },
      dangerMode: true,
    }).then(async (isConfirm) => {
      if (isConfirm) {
        await apiServices
          .httpRequest("DELETE", `user/${_id}`, null)
          .then((res) => {
            delete usersList[i];
            setUsersList(usersList);
            setKey((prev) => !prev);
            swal("Deleted!", res.message, "success");
          })
          .catch((error) => {
            swal("Deleted!", error, "error");
          });
      } else {
        swal("Cancelled", "User is safe :)", "error");
        e.preventDefault();
      }
    });
  }

  const userTemplate = (rowData) => {
    return (
      <Author
        image={team2}
        name={`${rowData.firstName} ${rowData.lastName}`}
        email={rowData.email}
      />
    )
  }

  const roleTemplate = (rowData) => {
    let badgeColour = (rowData.userType == "user") ? "success" : "info";
    return (
      <SuiBadge
        variant="gradient"
        badgeContent={rowData.userType}
        color={badgeColour}
        size="xs"
        container
      />
    )
  }

  const statusTemplate = (rowData, props) => {
    return (
      <Status status={rowData.status} index={props.rowIndex} />
    )
  }

  const actionTemplate = (rowData, props) => {
    return (
      <>
        <IconButton
          onClick={() =>
            navigate("/users/edit", {
              state: {
                data: {
                  userId: rowData._id,
                  email: rowData.email,
                  firstName: rowData.firstName,
                  lastName: rowData.lastName,
                  status: rowData.status,
                  role: rowData.userType,
                },
              },
            })
          }
        >
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
          >
            edit
          </Icon>
        </IconButton>
        <IconButton onClick={(e) => deleteUserById(e, props.rowIndex)}>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
          >
            delete
          </Icon>
        </IconButton>
      </>
    )
  }

  const onColumnSort = (data) => {

    var newSortOrder = data.order;
    var dataArray = data.data;

    if (currentSortOrder === newSortOrder && currentSortField === data.field) {
      return dataArray;
    }

    setCurrentSortOrder(newSortOrder);
    setCurrentSortField(data.field);

    if (newSortOrder === 1) {
      if (data.field === "user") {
        return dataArray.sort((a, b) => a.firstName.localeCompare(b.firstName));
      } else if (data.field === "role") {
        return dataArray.sort((a, b) => a.userType.localeCompare(b.userType));
      } else if (data.field === "status") {
        return dataArray.sort((a, b) => a.status.localeCompare(b.status));
      }

    } else {
      if (data.field === "user") {
        return dataArray.reverse((a, b) => a.firstName.localeCompare(b.firstName));
      } else if (data.field === "role") {
        return dataArray.reverse((a, b) => a.userType.localeCompare(b.userType));
      } else if (data.field === "status") {
        return dataArray.reverse((a, b) => a.status.localeCompare(b.status));
      }
    }

  };

  return (
    <>
      <SuiBox py={3}>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >

            <SuiTypography variant="h6" >Users List</SuiTypography>
            <div className="search-action-btn-wrap">
              <SuiBox pr={3} className="searchContainer" >
                <SuiInput
                  placeholder="Search here..."
                  size="small"
                  /*  icon={
                      {
                      component: "close",
                      direction: "right",
                      onClick:{clearSearch}
                    }}  */
                  onChange={debounceHandleChange}
                  name="search"
                  id="search"
                />
                <IconButton
                  size="small"
                  color="inherit"
                  className={searchClassName}
                  onClick={clearSearch}
                >
                  <Icon className="text-white">
                    close
                  </Icon>
                </IconButton>
              </SuiBox>

              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => navigate("/users/create")}
              >
                Create New User
              </SuiButton>
            </div>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >

            <DataTable value={usersList} sortField="user" sortOrder={1} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ padding: '10px', minWidth: '50rem' }}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
              <Column field="user" sortable sortFunction={onColumnSort} body={userTemplate} header="USER"></Column>
              <Column field="role" sortable sortFunction={onColumnSort} body={roleTemplate} header="ROLE"></Column>
              <Column field="status" sortable sortFunction={onColumnSort} header="STATUS" body={statusTemplate}></Column>
              <Column field="action" header="ACTION" body={actionTemplate}></Column>
            </DataTable>

          </SuiBox>
        </Card>
      </SuiBox>
    </>
  );
}

export default Tables;
