import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tables from "./Tables";
import { Route, Routes } from "react-router-dom";
import Create from "./Create";
import View from "./View";
import Edit from "./Edit";

function Teams(props) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Routes>
          <Route index element={<Tables />} />
          <Route path={`/create`} element={<Create />} />
          <Route path={`/view`} element={<View />} />
          <Route path={`/edit`} element={<Edit />} />
        </Routes>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Teams;
