import {
  Autocomplete,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { apiServices } from "services/apiServices";
import { toast } from "react-toastify";
import { ArrowDownward } from "@mui/icons-material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Password(props) {

  const formValidation = yup.object().shape({
    oldPassword: yup.string().min(1).max(25).required("Old Password is required"),
    newPassword: yup.string().min(1).max(25).required("New Password is required"),
    confirmPassword: yup.string().max(255).required("Confirm Password is required").oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  });
  const navigate = useNavigate();

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    let formData = new FormData();
    
    for (let value in values) {
      if(value !== "confirmPassword"){
        formData.append(value, values[value]);
      }
    }

    await apiServices
      .httpRequest("POST", "users/updatePassword", formData, "Accept")
      .then((res) => {
        resetForm({ values: "" });
        toast.success(res.message);
        navigate("../", { replace: true });
      })
      .catch((error) => toast.error(error.message));

    setSubmitting(false);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <Card>
            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SuiTypography variant="h6">Update Password</SuiTypography>

              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => navigate(-1)}
              >
                Back
              </SuiButton>
            </SuiBox>
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <SuiBox pt={2} pb={3} px={3}>
                <Formik
                  initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                  }}
                  validationSchema={formValidation}
                  onSubmit={(values, { setSubmitting, resetForm }) =>
                    handleSubmit(values, setSubmitting, resetForm)
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                    /* and other goodies */
                  }) => (
                    <SuiBox component="form" role="form" onSubmit={handleSubmit}>
                      <SuiBox mb={2}>
                        <Grid container spacing={3}>

                          <Grid item xs={12} md={4}>
                            <SuiTypography variant="h6">Current Password</SuiTypography>
                            <SuiInput
                              error={touched.oldPassword && errors.oldPassword && true}
                              success={touched.oldPassword && !errors.oldPassword && true}
                              placeholder="Current Password"
                              name="oldPassword"
                              value={values.oldPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="Password"
                            />

                            <FormHelperText error={true}>
                              {touched.oldPassword && errors.oldPassword && errors.oldPassword}
                            </FormHelperText>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <SuiTypography variant="h6">New Password</SuiTypography>
                            <SuiInput
                              error={touched.newPassword && errors.newPassword && true}
                              success={touched.newPassword && !errors.newPassword && true}
                              placeholder="New Password"
                              name="newPassword"
                              value={values.newPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="Password"
                            />

                            <FormHelperText error={true}>
                              {touched.newPassword && errors.newPassword && errors.newPassword}
                            </FormHelperText>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <SuiTypography variant="h6">Confirm Password</SuiTypography>
                            <SuiInput
                              error={touched.confirmPassword && errors.confirmPassword && true}
                              success={touched.confirmPassword && !errors.confirmPassword && true}
                              placeholder="Confirm Password"
                              name="confirmPassword"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="Password"
                            />

                            <FormHelperText error={true}>
                              {touched.confirmPassword && errors.confirmPassword && errors.confirmPassword}
                            </FormHelperText>
                          </Grid>

                        </Grid>
                      </SuiBox>
                      <SuiBox mt={4} mb={1}>
                        <SuiButton
                          variant="gradient"
                          color="dark"
                          size="small"
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                        >
                          Update
                        </SuiButton>
                      </SuiBox>
                    </SuiBox>
                  )}
                </Formik>
              </SuiBox>
            </SuiBox>
          </Card>
        </SuiBox>
      </DashboardLayout>
    </>
  );
}

export default Password;