import {
  Card,
  Grid,
  Icon,
  IconButton,
  Tooltip,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { apiServices } from "services/apiServices";
import { toast } from "react-toastify";
import SuiBadge from "components/SuiBadge";
import { constants } from "constants";
import StorageService from "services/StorageService";
import axios from "axios";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function PlatformUserfromApps(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [columns] = useState([
    { name: "id", align: "left" },
    { name: "version", align: "left" },
    { name: "status", align: "center" },
    { name: "action", align: "center" },
  ]);

  const platforms = state.platforms;

  const [value, setValue] = React.useState(0);
  const [selectedPlatform, setSelectedPlatform] = React.useState(platforms[0]);

  const handleChange = (event, newValue) => {
    setSelectedPlatform(platforms[newValue])
    setValue(newValue);
  };

  const inputData = {
    title: convertToSlug(state.appData.title),
    attachment: "",
    version: "",
    status: "inactive",
    appId: state.appData.appId,
    platform: state.platform,
    minimumOs: state.minimumOs,
  };
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  const formValidation = yup.object().shape({
    attachment: yup.string().min(1).max(25),
    version: yup
      .string()
      .required("Attachment is required")
      .test("is-decimal", "invalid version",
        (value) => !value || (value + "").match(/(^(\d{0,10}\.){0,10}(\d{0,20})$)/)
      ),
  });
  const cancelTokenSource = useRef();
  const [formInput] = useState(inputData);
  const [appsList, setAppsList] = useState([]);
  const [platForm, setPlatform] = useState([]);
  const [rows, setRows] = useState([]);
  const [key, setKey] = useState(false);
  const [uploader, setUploader] = useState(null);
  const [currentSortOrder, setCurrentSortOrder] = useState(1);
  const [currentSortField, setCurrentSortField] = useState("id");

  useEffect(() => {
    getAppAttachments();
  }, [key, selectedPlatform]);

  const createTableRows = (rows) => {
    // console.log(rows)
    let tableRows = [];
    rows.map((value, i) => {
      tableRows.push({
        id: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            #{i + 1}
          </SuiTypography>
        ),
        version: (
          <SuiBadge
            variant="gradient"
            badgeContent={`V_${value.version}`}
            color={"success"}
            size="xs"
            container
          />
        ),
        status: (
          <SuiBadge
            variant="gradient"
            badgeContent={value.status}
            color={value.status === "active" ? "success" : "error"}
            size="xs"
            container
          />
        ),

        action: (
          <>
            <Tooltip title="Download file">
              <IconButton
                onClick={() =>
                  getDataFromURL(constants.apiUrl + value.attachment)
                }
              >
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  fontSize="small"
                >
                  download
                </Icon>
              </IconButton>
            </Tooltip>
          </>
        ),
      });
    });
    setRows(tableRows);
  };

  const getAppAttachments = async () => {
    try {
      const appAttachments = await apiServices.httpRequest(
        "GET",
        `apps/user/attachments/${state.appData.appId}/${selectedPlatform}`,
        null
      );
      // if(appAttachments.length <= 0 ) {
      //   navigate(-1)
      // }
      setAppsList(appAttachments);
      createTableRows(appAttachments);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (values, setSubmitting, resetForm) => {
    let formData = new FormData();

    for (let value in values) {
      formData.append(value, values[value]);
    }

    cancelTokenSource.current = axios.CancelToken.source();
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        Authorization: `Bearer ${StorageService.getLogin().token}`,
      },
      data: formData,
      url: `${constants.apiUrl + "apps/attachments"}`,
      cancelToken: cancelTokenSource.current.token,
      onUploadProgress: (p) => {
        setUploader(Math.round((p.loaded / p.total) * 100));
      },
    };

    await axios(requestOptions)
      .then(function (response) {
        resetForm({ values: "" });
        toast.success(response.data.message);
        setKey((prev) => !prev);
        setTimeout(() => {
          setUploader(null);
        }, 3000);
      })
      .catch(function (error) {
        const { message } = error;
        toast.error(message);
        setKey((prev) => !prev);
        setTimeout(() => {
          setUploader(null);
        }, 3000);
      });
    setSubmitting(false);
  };

  const getDataFromURL = (url) => {
    //console.log("hello", url);
    var fileName = url.slice(url.lastIndexOf("/") + 1);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  const cancel = () => {
    cancelTokenSource.current.cancel();
  };

  let logo_val = "bishop-placeholder-logo.jpeg"
  if (state.appData.logo === "" || state.appData.logo === `${constants.appUrl}${logo_val}`) {
    state.appData.logo = logo_val;
  } else {
    state.appData.logo = state.appData.logo;
  }

  const onColumnClickSort = (data) => {

    var newSortOrder = data.order;
    var dataArray = data.data;

    if (currentSortOrder === newSortOrder && currentSortField === data.field) {
      return dataArray;
    }

    setCurrentSortOrder(newSortOrder);
    setCurrentSortField(data.field);

    if (data.field == "id") {

      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.id.props.children[1] - b.id.props.children[1]);
      } else {
        return dataArray.reverse((a, b) => a.id.props.children[1] - b.id.props.children[1]);
      }

    } else if (data.field == "version") {

      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.version.props.badgeContent.localeCompare(b.version.props.badgeContent));
      } else {
        return dataArray.reverse((a, b) => a.version.props.badgeContent.localeCompare(b.version.props.badgeContent));
      }

    } else if (data.field == "status") {

      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
      } else {
        return dataArray.reverse((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
      }

    }

    return dataArray;
  };

  return (
    <>
      <SuiBox py={3}>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >

            <Box sx={{ width: '100%' }}>

              {

                (platforms.length > 0) &&
                <>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="App attachments" className="AppPlatforms">
                      {platforms.map((platform, index) => (
                        <Tab label={platform} {...a11yProps(index)} key={index} />
                      ))
                      }
                    </Tabs>
                  </Box>
                </>
              }

              {
                (platforms.length > 0) ?
                  platforms.map((platform, index) => (
                    <CustomTabPanel value={value} index={index} key={index}>
                      <SuiBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={3}
                      >
                        <SuiBox>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item>
                              <SuiBox
                                component="img"
                                src={state.appData.logo === logo_val ? constants.appUrl + state.appData.logo : state.appData.logo}
                                alt="App Logo"
                                width="8rem"
                                shadow="sm"
                              />
                            </Grid>

                            <Grid item>
                              <SuiBox height="100%" mt={0.5} lineHeight={1}>
                                <SuiTypography variant="h5" fontWeight="medium">
                                  {state.appData.title}
                                </SuiTypography>
                                <SuiTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                >
                                  Compatible with {platform} {state.os && state.os}
                                </SuiTypography>
                                <SuiBox lineHeight="2">
                                  <SuiButton
                                    variant="gradient"
                                    color="dark"
                                    size="small"
                                    onClick={() =>
                                      getDataFromURL(
                                        constants.apiUrl + appsList[0].attachment
                                      )
                                    }
                                  >
                                    Download Latest Version
                                  </SuiButton>
                                </SuiBox>
                              </SuiBox>
                            </Grid>
                          </Grid>
                        </SuiBox>

                        <SuiBox>
                          <SuiButton
                            variant="gradient"
                            color="dark"
                            size="small"
                            onClick={() => navigate(-1)}
                          >
                            Back
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>

                      <SuiBox
                        sx={{
                          "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                            },
                          },
                        }}
                      ></SuiBox>
                      <SuiBox
                        sx={{
                          "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                            },
                          },
                        }}
                      >

                        <DataTable value={rows} sortField="id" sortOrder={1} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ padding: '10px', minWidth: '50rem' }}
                          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                          currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
                          <Column field="id" sortable sortFunction={onColumnClickSort} header="ID"></Column>
                          <Column field="version" sortable sortFunction={onColumnClickSort} header="VERSION"></Column>
                          <Column field="status" sortable sortFunction={onColumnClickSort} header="STATUS"></Column>
                          <Column field="action" header="ACTION"></Column>
                        </DataTable>

                      </SuiBox>
                    </CustomTabPanel>

                  )) :
                  <SuiBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <SuiBox>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <SuiBox
                            component="img"
                            src={state.appData.logo === logo_val ? constants.appUrl + state.appData.logo : state.appData.logo}
                            alt="App Logo"
                            width="8rem"
                            shadow="sm"
                          />
                        </Grid>

                        <Grid item>
                          <SuiBox height="100%" mt={0.5} lineHeight={1}>
                            <SuiTypography variant="h5" fontWeight="medium">
                              {state.appData.title}
                            </SuiTypography>
                            <SuiTypography
                              variant="button"
                              color="text"
                              fontWeight="medium"
                            >

                            </SuiTypography>

                          </SuiBox>
                        </Grid>
                      </Grid>
                    </SuiBox>

                    <SuiBox>
                      <SuiButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
              }

            </Box>

          </SuiBox>

        </Card>
      </SuiBox>
    </>
  );
}

export default PlatformUserfromApps;