import {
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import Table from "examples/Tables/Table";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { apiServices } from "services/apiServices";
import { toast } from "react-toastify";
import SuiBadge from "components/SuiBadge";
import SuiAvatar from "components/SuiAvatar";
import burceMars from "assets/images/bruce-mars.jpg";
import { constants } from "constants";
import StorageService from "services/StorageService";
import axios from "axios";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OtherLayout from "layouts/authentication/components/OtherLayout";

function UserDasboard(props) {
  const navigate = useNavigate();
  let { appId } = useParams();
  const [columns] = useState([
    { name: "id", align: "left" },
    { name: "platform", align: "left" },
    { name: "version", align: "left" },
    { name: "status", align: "center" },
    { name: "action", align: "center" },
  ]);

  const [app, setApp] = useState({});
  const [rows, setRows] = useState([]);
  const [key, setKey] = useState(false);
  const [uploader, setUploader] = useState(null);
  const [platForm, setPlatform] = useState('Android');

  useEffect(() => {
    getAppAttachments(platForm);
  }, [key, platForm]);

  const getAppAttachments = async (platForm) => {
    try {
      
      const data = await apiServices.httpRequestNonAuth(
        "GET",
        `public/${appId}/${platForm}`,
        null
      ) 
     
      if(data){
        if(data.app.type === "private"){
          navigate("/authentication/sign-in"); 
        }  

        setApp(data.app);
      
        // const resultArray = Object.keys(data.innerArray).map(
        //   (index) => {
        //     let person = data.innerArray[index];
        //     return person;
        //   }
        // );
         
        createTableRows(data.attachments);
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const createTableRows = (rows) => {
    let tableRows = [];
    rows.map((value, i) => {
      tableRows.push({
        id: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
            #{i + 1}
          </SuiTypography>
        ),
        platform: (
          <SuiTypography
            pl={2}
            variant="button"
            color="text"
            fontWeight="medium"
          >
           {value.platform}
          </SuiTypography>
        ),
        version: (
          <SuiBadge
            variant="gradient"
            badgeContent={`V_${value.version}`}
            color={"success"}
            size="xs"
            container
          />
        ),
        status: (
          <SuiBadge
            variant="gradient"
            badgeContent={value.status}
            color={value.status === "active" ? "success" : "error"}
            size="xs"
            container
          />
        ),

        action: (
          <>
            <Tooltip title="Download file">
              <IconButton
                onClick={() =>
                  getDataFromURL(constants.apiUrl + value.attachment)
                }
              >
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  fontSize="small"
                >
                  download
                </Icon>
              </IconButton>
            </Tooltip>
          </>
        ),
      });
    });
    setRows(tableRows);
  };

  const getDataFromURL = (url) => {
    var fileName = url.slice(url.lastIndexOf("/") + 1);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handleChange = async(e)=> {
    const {value} = e.target
    setRows([])
    setPlatform(value)
  }
 
  let logo_val = "bishop-placeholder-logo.jpeg";
  if(app.logo ==="" || app.logo ===`${constants.appUrl}${logo_val}`){    
    app.logo= `${constants.appUrl}${logo_val}`;
   }else{
    app.logo=  constants.apiUrl + app.logo
   }
  return (
    <>
      <OtherLayout>
        <SuiBox py={3}>
          <Card>
            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SuiBox>
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    <SuiBox                      
                    component="img"
                     src={ app !== "" ? constants.apiUrl + app.logo  : ""} 
                      alt="App Logo"
                      width="8rem"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <SuiBox height="100%" mt={0.5} lineHeight={1}>
                      <SuiTypography variant="h5" fontWeight="medium">
                        {app.title}
                      </SuiTypography>
                      <SuiTypography
                        variant="button"
                        color="text"
                        fontWeight="medium"
                      >
                         Compatible with <Select
                              id="mui-select"
                              name="type"
                              value={platForm}
                              onChange={handleChange}
                              IconComponent={ExpandMoreIcon}
                            >
                              {app.platform && app?.platform.map(type => <MenuItem key={type} value={type}>{type.toUpperCase()}</MenuItem>)}
                              
                            </Select>
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
              {/* <SuiBox>
                <SuiButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={() => navigate(-1)}
                >
                  Back
                </SuiButton>
              </SuiBox> */}
            </SuiBox>
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            ></SuiBox>
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </SuiBox>
          </Card>
        </SuiBox>
      </OtherLayout>
    </>
  );
}

export default UserDasboard;
