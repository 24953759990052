import {
    Card,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { apiServices } from "services/apiServices";
import { toast } from "react-toastify";
import SuiBadge from "components/SuiBadge";
import { constants } from "constants";
import StorageService from "services/StorageService";
import axios from "axios";
import swal from "sweetalert";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function View(props) {
    const navigate = useNavigate();
    const { state } = useLocation();

    const platforms = state.platform;

    const [value, setValue] = React.useState(0);
    const [selectedPlatform, setSelectedPlatform] = React.useState(platforms[0]);

    const handleChange = (event, newValue) => {
        setSelectedPlatform(platforms[newValue])
        setValue(newValue);
    };

    const inputData = {
        title: convertToSlug(state.title),
        attachment: state.attachment,
        version: "",
        status: "inactive",
        type: state.type,
        appId: state.id,
        platform: selectedPlatform,
        minimumOs: state.minimumOs,
    };
    function convertToSlug(Text) {
        return Text.toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");
    }
    const checkFormat = (value) => {
        if (selectedPlatform === "iOS") {
            return selectedPlatform.split(".").pop();
        } else if (selectedPlatform === "Windows") {
            return ["application/x-ms-dos-executable", "application/zip"];
        } else if (selectedPlatform === "Android") {
            return ["application/vnd.android.package-archive"];
        } else if (selectedPlatform === "macOS") {
            return ["application/x-ms-application", "application/zip"];
        } else if (selectedPlatform === "Ubuntu") {
            return ["application/vnd.debian.binary-package"];
        }
    };

    const errorMassages = () => {
        if (selectedPlatform === "iOS") {
            return ["Only .ipa format accepted for iOS"];
        } else if (selectedPlatform === "Windows") {
            return ["Only .exe or .zip format accepted for Windows"];
        } else if (selectedPlatform === "Android") {
            return ["Only .apk format accepted for Android"];
        } else if (selectedPlatform === "macOS") {
            return ["Only .app or .zip format accepted for macOS"];
        } else if (selectedPlatform === "Ubuntu") {
            return ["Only .deb format accepted for Ubuntu"];
        }
    };

    const formValidation = yup.object().shape({
        attachment: yup
            .mixed().required("Attachment is required")
            .test("type", errorMassages(),
                (value) => (
                    value && checkFormat(value).includes(value.type)
                )
            ),
        version: yup
            .string()
            .test("is-decimal", "invalid version", (value) =>
                (value + "").match(/(^(\d{0,10}\.){0,10}(\d{0,20})$)/)
            ),
    });
    const cancelTokenSource = useRef();
    const [formInput] = useState(inputData);

    const [rows, setRows] = useState([]);
    const [key, setKey] = useState(false);
    const [uploader, setUploader] = useState(null);

    const [currentSortOrder, setCurrentSortOrder] = useState(1);
    const [currentSortField, setCurrentSortField] = useState("id");

    useEffect(() => {
        getAppAttachments();
    }, [key, selectedPlatform]);

    const createTableRows = (rows) => {
        let tableRows = [];
        rows.map((value, i) => {
            tableRows.push({
                id: (
                    <SuiTypography
                        pl={2}
                        variant="button"
                        color="text"
                        fontWeight="medium"
                    >
                        {i + 1}
                    </SuiTypography>
                ),
                version: (
                    <SuiBadge
                        variant="gradient"
                        badgeContent={`V_${value.version}`}
                        color={"success"}
                        size="xs"
                        container
                    />
                ),
                status: (
                    <SuiBadge
                        variant="gradient"
                        badgeContent={value.status}
                        color={value.status === "active" ? "success" : "error"}
                        size="xs"
                        container
                    />
                ),
                action: (
                    <>
                        <Tooltip title="Download file">
                            <IconButton
                                onClick={() =>
                                    getDataFromURL(constants.apiUrl + value.attachment)
                                }
                                data={constants.apiUrl + value.attachment}
                            >
                                <Icon
                                    sx={{ cursor: "pointer", fontWeight: "bold" }}
                                    fontSize="small"
                                >
                                    download
                                </Icon>
                            </IconButton>
                        </Tooltip>
                    </>
                ),
            });
        });
        setRows(tableRows);
    };

    const getAppAttachments = async () => {
        try {
            const appAttachments = await apiServices.httpRequest(
                "GET",
                `apps/attachments/${state.id}/${selectedPlatform}`,
                null
            );
            createTableRows(appAttachments);
        } catch (error) {
            console.log('error => ', error);
        }
    };

    const handleSubmit = async (values, setSubmitting, resetForm) => {

        let formData = new FormData();
        let formData1 = new FormData();

        for (let value in values) {
            formData.append(value, values[value]);
        }
        if (values.attachment.size !== "") {
            // formData1.append('size', values.attachment.size);
        } else {
            //  formData1.append('size', "");
        }
        formData1.append('size', values.attachment.size);
        formData1.append('name', values.attachment.name);
        formData1.append('version', values.version);
        formData1.set('platform', selectedPlatform);
        formData1.set('appId', inputData.appId);

        formData.set('platform', selectedPlatform);

        cancelTokenSource.current = axios.CancelToken.source();

        const requestOptions1 = {
            method: "POST",
            headers: {
                Accept: "application/form-data",
                Authorization: `Bearer ${StorageService.getLogin().token}`,
            },
            data: formData1,
            url: `${constants.apiUrl + "apps/attachmentsCheck"}`,
        };

        await axios(requestOptions1)
            .then(async (res) => {
                if (res.data.larger) {
                    toast.error("Enter larger version")
                    return
                }
                if (res.data.existFile) {
                    swal({
                        closeOnClickOutside: false,
                        title: "Are you sure?",
                        text: "App is already exist. Do you want to upload? ",
                        icon: "error",
                        buttons: {
                            cancel: "Cancel",
                            confirm: {
                                text: "Yes, I am sure",
                                name: "yesButton",
                            },
                        },
                        dangerMode: true,
                    }).then(async (isConfirm) => {
                        if (isConfirm) {
                            const requestOptions = {
                                method: "POST",
                                headers: {
                                    Accept: "application/form-data",
                                    Authorization: `Bearer ${StorageService.getLogin().token}`,
                                },
                                data: formData,
                                url: `${constants.apiUrl + "apps/attachments"}`,
                                cancelToken: cancelTokenSource.current.token,
                                onUploadProgress: (p) => {
                                    setUploader(Math.round((p.loaded / p.total) * 100));
                                },
                            };
                            await axios(requestOptions)
                                .then(function (response) {
                                    resetForm({ values: "" });
                                   
                                    toast.success(response.data.message);
                                    setKey((prev) => !prev);
                                    const notification = response.data.notification;
                                    const version = response.data.appdata.version;
                                    const appId = response.data.appdata.appId;

                                    notification.map((value, i) => {
                                        const notification1 = value.notification;
                                        const userId = value.userId;

                                        if (notification1 == "yes") {

                                            const apps = apiServices.httpRequest(
                                                "PUT",
                                                `users/sendAppLink/${userId}/${appId}/${version}`,

                                                JSON.stringify({
                                                    link: `${constants.appUrl}user/home/${appId}`,
                                                })
                                            );
                                        }
                                    });

                                    setTimeout(() => {
                                        setUploader(null);
                                        document.getElementsByClassName('MuiInputBase-input').value = null;
                                    }, 3000);
                                })
                                .catch((err) => {
                                    console.log(err);
                                    if (err) {
                                        console.log(err);
                                        toast("App version already used");
                                    }
                                });
                        } else {
                            swal("Cancelled", "App is not uploaded :)", "error");
                        }
                        setTimeout(() => {
                            setUploader(null);
                        }, 3000);
                    });
                } else {
                    if (res.data.larger) {
                        toast.error("Enter larger version")
                        return
                    }

                    const requestOptions = {
                        method: "POST",
                        headers: {
                            Accept: "application/form-data",
                            Authorization: `Bearer ${StorageService.getLogin().token}`,
                        },
                        data: formData,
                        url: `${constants.apiUrl + "apps/attachments"}`,
                        cancelToken: cancelTokenSource.current.token,
                        onUploadProgress: (p) => {
                            setUploader(Math.round((p.loaded / p.total) * 100));
                        },
                    };

                    await axios(requestOptions)
                        .then(function (response) {
                            console.log(response);
                            resetForm({ values: "" });
                            toast.success(response.data.message);
                            setKey((prev) => !prev);
                            const notification = response.data.notification;
                            const version = response.data.appdata.version;
                            const appId = response.data.appdata.appId;

                            notification.map((value, i) => {
                                const notification1 = value.notification;
                                const userId = value.userId;

                                if (notification1 == "yes") {

                                    const apps = apiServices.httpRequest(
                                        "PUT",
                                        `users/sendAppLink/${userId}/${appId}/${version}`,

                                        JSON.stringify({
                                            link: `${constants.appUrl}user/home/${appId}`,
                                        })
                                    );
                                }
                            });

                            setTimeout(() => {
                                setUploader(null);
                            }, 3000);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }

            })
            .catch((error) => {
                console.log(error);
            });

        setSubmitting(false);
    };

    const getDataFromURL = (url) => {
        var fileName = url.slice(url.lastIndexOf("/") + 1);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    const cancel = () => {
        cancelTokenSource.current.cancel();
    };

    let logo_val = "bishop-placeholder-logo.jpeg";

    if (state.logo === "" || state.logo === `${constants.appUrl}${logo_val}`) {
        state.logo = logo_val;
    } else {
        state.logo = state.logo;
    }

    const onColumnClickSort = (data) => {

        var newSortOrder = data.order;
        var dataArray = data.data;

        if (currentSortOrder === newSortOrder && currentSortField === data.field) {
            return dataArray;
        }

        setCurrentSortOrder(newSortOrder);
        setCurrentSortField(data.field);

        if (data.field == "id") {

            if (newSortOrder === 1) {
                return dataArray.sort((a, b) => a.id.props.children - b.id.props.children);
            } else {
                return dataArray.reverse((a, b) => a.id.props.children - b.id.props.children);
            }

        } else if (data.field == "version") {

            if (newSortOrder === 1) {
                return dataArray.sort((a, b) => a.version.props.badgeContent.localeCompare(b.version.props.badgeContent));
            } else {
                return dataArray.reverse((a, b) => a.version.props.badgeContent.localeCompare(b.version.props.badgeContent));
            }

        } else if (data.field == "status") {

            if (newSortOrder === 1) {
                return dataArray.sort((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
            } else {
                return dataArray.reverse((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
            }

        }

        return dataArray;
    };

    return (
        <>
            <SuiBox py={3}>
                <Card>
                    <SuiBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={3}
                    >
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="App attachments" className="AppPlatforms">

                                    {

                                        platforms.map((platform, index) => (
                                            <Tab label={platform} {...a11yProps(index)} key={index} />
                                        ))
                                    }

                                </Tabs>
                            </Box>

                            {
                                platforms.map((platform, index) => (
                                    <CustomTabPanel value={value} index={index} key={index}>

                                        <SuiBox
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            p={3}
                                        >
                                            <SuiBox>
                                                <Grid container spacing={3} alignItems="center">
                                                    <Grid item>
                                                        <SuiBox
                                                            component="img"
                                                            src={state.logo === logo_val ? constants.appUrl + state.logo : state.logo}
                                                            alt="App Logo"
                                                            width="8rem"
                                                            shadow="sm"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <SuiBox height="100%" mt={0.5} lineHeight={1}>
                                                            <SuiTypography variant="h5" fontWeight="medium">
                                                                {state.title}
                                                            </SuiTypography>
                                                            <SuiTypography
                                                                variant="button"
                                                                color="text"
                                                                fontWeight="medium"
                                                            >
                                                                Compatible with {platform} {state.os && state.os}
                                                            </SuiTypography>
                                                        </SuiBox>
                                                    </Grid>
                                                </Grid>
                                            </SuiBox>
                                            <SuiBox>
                                                <SuiButton
                                                    variant="gradient"
                                                    color="dark"
                                                    size="small"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Back
                                                </SuiButton>
                                            </SuiBox>
                                        </SuiBox>
                                        <SuiBox
                                            sx={{
                                                "& .MuiTableRow-root:not(:last-child)": {
                                                    "& td": {
                                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                            `${borderWidth[1]} solid ${borderColor}`,
                                                    },
                                                },
                                            }}
                                        >
                                            <SuiBox pb={3} px={3}>
                                                <Formik
                                                    initialValues={formInput}
                                                    validationSchema={formValidation}
                                                    onSubmit={(values, { setSubmitting, resetForm }) =>
                                                        handleSubmit(values, setSubmitting, resetForm)
                                                    }
                                                >
                                                    {({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting,
                                                        setFieldValue,
                                                        /* and other goodies */
                                                    }) => (
                                                        <SuiBox
                                                            component="form"
                                                            role="form"
                                                            onSubmit={handleSubmit}
                                                            encType="multipart/form-data"
                                                        >
                                                            <SuiBox mb={2}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} md={4}>
                                                                        <SuiTypography variant="h6">Attachment</SuiTypography>
                                                                        <SuiInput
                                                                            sx={{ padding: 0 }}
                                                                            error={
                                                                                touched.attachment && errors.attachment && true
                                                                            }
                                                                            success={
                                                                                touched.attachment && !errors.attachment && true
                                                                            }
                                                                            type={"file"}
                                                                            name="attachment"
                                                                            // value={values.type}
                                                                            onChange={(e) =>
                                                                                setFieldValue(
                                                                                    "attachment",
                                                                                    e.currentTarget.files[0]
                                                                                )
                                                                            }
                                                                            onBlur={handleBlur}
                                                                            id="attachment"
                                                                        />

                                                                        <FormHelperText error={true}>
                                                                            {touched.attachment &&
                                                                                errors.attachment &&
                                                                                errors.attachment}
                                                                        </FormHelperText>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={4}>
                                                                        <SuiTypography variant="h6">Version</SuiTypography>
                                                                        <SuiInput
                                                                            error={touched.version && errors.version && true}
                                                                            success={touched.version && !errors.version && true}
                                                                            placeholder="Version"
                                                                            name="version"
                                                                            value={values.version}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                        />

                                                                        <FormHelperText error={true}>
                                                                            {touched.version &&
                                                                                errors.version &&
                                                                                errors.version}
                                                                        </FormHelperText>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={4}>
                                                                        <FormControl sx={{ width: "100%" }}>
                                                                            <SuiTypography variant="h6">
                                                                                Select Status
                                                                            </SuiTypography>
                                                                            <Select
                                                                                id="mui-select"
                                                                                name="status"
                                                                                value={values.status}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                            >
                                                                                <MenuItem value={"active"}>Active</MenuItem>
                                                                                <MenuItem value={"inactive"}>Inactive</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                        <FormHelperText error={true}></FormHelperText>
                                                                    </Grid>
                                                                </Grid>
                                                            </SuiBox>
                                                            <SuiBox mt={2} mb={1}>
                                                                <SuiButton
                                                                    variant="gradient"
                                                                    color="dark"
                                                                    size="small"
                                                                    onClick={handleSubmit}
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {uploader ? (
                                                                        <SuiBox mt={1} mb={1}>
                                                                            <SuiBox
                                                                                sx={{
                                                                                    position: "relative",
                                                                                    display: "inline-flex",
                                                                                }}
                                                                            >
                                                                                <CircularProgress
                                                                                    variant="determinate"
                                                                                    value={uploader}
                                                                                    color="success"
                                                                                />
                                                                                <SuiBox
                                                                                    sx={{
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                        bottom: 0,
                                                                                        right: 0,
                                                                                        position: "absolute",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                    }}
                                                                                >
                                                                                    <SuiTypography
                                                                                        variant="caption"
                                                                                        component="div"
                                                                                        color="light"
                                                                                    >
                                                                                        {`${Math.round(uploader)}%`}
                                                                                    </SuiTypography>
                                                                                </SuiBox>
                                                                            </SuiBox>
                                                                        </SuiBox>
                                                                    ) : (
                                                                        "Upload"
                                                                    )}
                                                                </SuiButton>
                                                            </SuiBox>

                                                            {uploader && (
                                                                <SuiBox>
                                                                    <SuiButton
                                                                        variant="gradient"
                                                                        color="dark"
                                                                        size="small"
                                                                        onClick={cancel}
                                                                    >
                                                                        Cancel
                                                                    </SuiButton>
                                                                </SuiBox>
                                                            )}
                                                        </SuiBox>
                                                    )}
                                                </Formik>
                                            </SuiBox>
                                        </SuiBox>
                                        <SuiBox
                                            sx={{
                                                "& .MuiTableRow-root:not(:last-child)": {
                                                    "& td": {
                                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                            `${borderWidth[1]} solid ${borderColor}`,
                                                    },
                                                },
                                            }}
                                        >

                                            <DataTable value={rows} sortField="id" sortOrder={1} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ padding: '10px', minWidth: '50rem' }}
                                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                                currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
                                                <Column field="id" sortable sortFunction={onColumnClickSort} header="ID"></Column>
                                                <Column field="version" sortable sortFunction={onColumnClickSort} header="VERSION"></Column>
                                                <Column field="status" sortable sortFunction={onColumnClickSort} header="STATUS"></Column>
                                                <Column field="action" header="ACTION"></Column>
                                            </DataTable>

                                        </SuiBox>

                                    </CustomTabPanel>
                                ))
                            }

                        </Box>
                    </SuiBox>
                </Card>
            </SuiBox>
        </>
    );
}

export default View;  