// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

import { useEffect, useState } from "react";
import { apiServices } from "services/apiServices";
import { color } from "@mui/system";

function Overview() {
  const [profile, setProfile] = useState({});
  useEffect(() => {
    getProfile();
  }, []);

  async function getProfile() {
    await apiServices
      .httpRequest("get", "profile", null)
      .then((res) => setProfile(res.profile))
      .catch((error) => console.log(error));
  }

  return (
    <DashboardLayout>
      <Header profile={profile} />
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={6} xl={4}>
            <PlatformSettings />
          </Grid> */}
          <Grid item xs={12} md={6} xl={5}>
            <ProfileInfoCard 
              title="profile information"
              
              info={{
                fullName: `${profile.firstName} ${profile.lastName}`,
                email: `${profile.email}`,
                firstName: profile.firstName,
                lastName: profile.lastName,
                status: profile.status,
                role: profile.userType,
                userId: profile._id,
              }}
              // social={[
              //   {
              //     link: "https://www.facebook.com/CreativeTim/",
              //     icon: <FacebookIcon />,
              //     color: "facebook",
              //   },
              //   {
              //     link: "https://twitter.com/creativetim",
              //     icon: <TwitterIcon />,
              //     color: "twitter",
              //   },
              //   {
              //     link: "https://www.instagram.com/creativetimofficial/",
              //     icon: <InstagramIcon />,
              //     color: "instagram",
              //   },
              // ]}
              action={{ route: "", tooltip: "Edit Profile" } }
            />
          </Grid>
          {/* <Grid item xs={12} xl={4}>
            <ProfilesList title="conversations" profiles={profilesListData} />
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
