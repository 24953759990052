/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link, useNavigate } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

function ProfileInfoCard({ title, description, info, action }) {
  const navigate = useNavigate();
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (["fullName", "email"].includes(el)) {
      if (el.match(/[A-Z\s]+/)) {
        const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
        const newElement = el.replace(
          uppercaseLetter,
          ` ${uppercaseLetter.toLowerCase()}`
        );

        labels.push(newElement);
      } else {
        labels.push(el);
      }
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <SuiBox key={label} display="flex" py={1} pr={2}>
      <SuiTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}: &nbsp;
      </SuiTypography>
      <SuiTypography variant="button" fontWeight="regular" color="text"  textTransform={label === 'email' ? 'none' : 'capitalize'}>
        &nbsp;{ values[key]}
      </SuiTypography>
    </SuiBox>
  ));

  // Render the card social media icons
  // const renderSocial = social.map(({ link, icon, color }) => (
  //   <SuiBox
  //     key={color}
  //     component="a"
  //     href={link}
  //     target="_blank"
  //     rel="noreferrer"
  //     fontSize={size.lg}
  //     color={socialMediaColors[color].main}
  //     pr={1}
  //     pl={0.5}
  //     lineHeight={1}
  //   >
  //     {icon}
  //   </SuiBox>
  // ));

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <SuiTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </SuiTypography>
        <SuiTypography
          variant="body2"
          color="secondary"
          onClick={() =>
            navigate("/users/profile/edit", {
              state: {
                data: {
                  userId: info.userId,
                  email: info.email,
                  firstName: info.firstName,
                  lastName: info.lastName,
                  status: info.status,
                  role: info.role,
                },
              },
            })
          }
        >
          <Tooltip title={action.tooltip} placement="top">
            <Icon style={{ cursor: "pointer" }}>edit</Icon>
          </Tooltip>
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox mb={2} lineHeight={1}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            {description}
          </SuiTypography>
        </SuiBox>
        <SuiBox opacity={0.3}>
          <Divider />
        </SuiBox>
        <SuiBox>
          {renderItems}
          {/* <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              social: &nbsp;
            </SuiTypography>
            {renderSocial}
          </SuiBox> */}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  // social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProfileInfoCard;
