// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Table from "examples/Tables/Table";

// Data
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiAvatar from "components/SuiAvatar";
import { apiServices } from "services/apiServices";
import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { loggedIn, userProfile } from "redux/reduxSlice/UserSlice";
// react-router-dom components
import { useLocation, NavLink, useNavigate, useParams } from "react-router-dom";

/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiProgress from "components/SuiProgress";

// Images
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import SuiBadge from "components/SuiBadge";
import {
  Avatar,
  // Button,
  capitalize,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import swal from "sweetalert";
import { constants } from "constants";
import moment from "moment";

import { helper } from "services/helper";

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

function TableUsers() {
  const navigate = useNavigate();
  const [columns] = useState([
    { name: "apps", align: "left" },
    { name: "description", align: "left" },
    { name: "status", align: "center" },
    { name: "action", align: "center" },
  ]);
  const [rows, setRows] = useState([]);
  const [key, setKey] = useState(false);

  const [openSettings, setOpenSettings] = useState(false);
  const [platformData, setPlatformData] = useState([]);
  const [os, setOs] = useState([]);
  const [appDetails, setAppDetails] = useState({
    appId: "",
    title: "",
  });
  const [appDetails1, setAppDetails1] = useState([]);
  const location = useLocation();
  const userProfile = useSelector((state) => state.isLogin.profile);
  const [appDetailsNew, setAppDetailsNew] = useState({
    appId: "",
    logo: "",
    title: "",
    description: "",
    status: "inactive",
    type: "public",
    platform: [],
  });

  const [open, setOpen] = useState(false);
  const [searchClassName, setSearchClassName] = useState("clearBtn hide");

  const [currentSortOrder, setCurrentSortOrder] = useState(1);
  const [currentSortField, setCurrentSortField] = useState("title");

  const debounceHandleChange = helper.debounce((e) => handleChange(e));

  const handleChange = async (e) => {
    const { value } = e.target
    setSearchClassName((value.length > 0) ? 'clearBtn' : 'clearBtn hide');
    getApps(value);
  }

  const clearSearch = () => {
    const searchInput = document.getElementById('search');
    searchInput.value = '';
    setSearchClassName("clearBtn hide");
    getApps();
  }

  useEffect(() => {
    getApps();
  }, [key]);
  const appEdit = async (e) => {
    const _id = e.currentTarget.dataset.id;
    await apiServices
      .httpRequest("GET", `apps/edit/${_id}`, null)
      .then((res) => {
        // console.log(res);
      });
  };
  const handleOpenSettings = async (event, data) => {
    setAppDetails({
      appId: data.appId,
      title: data.title,
      logo: data.logo,
    });
    setOs(data.os);
    setPlatformData(data.platform);
    if (data.platform.length > 0) {
      setOpenSettings(event.currentTarget);
    }
  };
  const handleCloseSettings = () => setOpenSettings(false);
  const renderSettings = () => (
    <Menu
      anchorEl={openSettings}
      id="account-menu"
      open={Boolean(openSettings)}
      onClose={handleCloseSettings}
      onClick={handleCloseSettings}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {platformData.map((res, i) => {
        return (
          <MenuItem
            key={i}
            onClick={() => viewPlatform(res, appDetails, os[i][res])}
          >
            {capitalize(res)}
          </MenuItem>
        );
      })}
    </Menu>
  );

  function Platform({ data, index }) {
    return (
      <>
        <IconButton onClick={(e) => handleOpenSettings(e, data)}>
          <Tooltip title="Click and check Compatibility with platforms">
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
            >
              visibility
            </Icon>
          </Tooltip>
        </IconButton>
      </>
    );
  }

  /* function viewPlatform(value, appData, os) {

    navigate("/projects/user/platform", {
      state: {
        platform: value,
        appData: appData,
        os: os,
      },
    });
  } */

  function viewPlatform(value, appData, os) {

    navigate("/projects/user/platform", {
      state: {
        platform: value[0],
        platforms: value,
        appData: appData,
        os: os,
      },
    });
  }

  function notifyApp(value) { }

  function Notification({ notification, index, appId }) {
    return (
      <IconButton onClick={(e) => setNotification(notification, appId)}>
        <Icon
          sx={{
            cursor: "pointer",
            fontWeight: "bold",
            color: notification == "yes" ? "orange" : "grey",
          }}
          fontSize="small"
        >
          notifications
        </Icon>
      </IconButton>
    );
  }

  async function setNotification(notification, appId) {
    if (notification == "yes") {
      notification = "no";
    } else {
      notification = "yes";
    }

    const userId = userProfile.userId;
    // console.log(userId);
    await apiServices
      .httpRequest(
        "PUT",
        `apps/edit/${appId}`,
        JSON.stringify({ notification: notification, users: userId })
      )
      .then((res) => {
        swal("Notification Changed", "", "success");
        getApps();
      })
      .catch((err) => console.log(err));
  }

  function viewApp(value, logo, title, description, status, type, platform) {
    navigate(`/projects/view/${value}`, {
      state: {
        id: value,
        logo: logo,
        title: title,
        description: description,
        status: status,
        type: type,
        platform: platform,
      },
    });
  }

  const createTableRows = (rows, notifi) => {
    let tableRows = [];
    const userId = userProfile.userId;

    rows.map((value, i) => {
      const id = value._id;
      const status = value.status;
      let logoval = "";
      if (value.logo === "") {
        let logo_val = "bishop-placeholder-logo.jpeg"
        logoval = `${constants.appUrl}${logo_val}`;
      } else {
        logoval = `${constants.apiUrl}${value.logo}`
      }
      if (status != "active") {
        return false;
      }

      var appnotification = "no";
      if (notifi) {
        if (notifi[i]) {
          if (id == notifi[i].appId) {
            var appnotification = notifi[i].notification;
          }
        }
      }
      tableRows.push({
        id: value._id,
        platform: value.platform,
        apps: [`${logoval}`, value.title],
        description: (
          <Tooltip title={value.description}>
            <SuiTypography variant="button" color="text" fontWeight="medium">
              {value.description}
            </SuiTypography>
          </Tooltip>
        ),
        status: (
          <SuiBadge
            variant="gradient"
            badgeContent={value.status}
            color={value.status === "active" ? "success" : "error"}
            size="xs"
            container
          />
        ),

        action: (
          <>
            {/* <Platform
              data={{
                title: value.title,
                platform: value.platform,
                appId: value._id,
                logo: value.logo,
                os: value.minimumOs,
              }}
              index={i}
            /> */}

            <IconButton
              onClick={() =>
                viewPlatform(
                  value.platform,
                  {
                    appId: value._id,
                    title: value.title,
                    logo: value.logo,
                  },
                  ""
                )
              }
            >
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
              >
                visibility
              </Icon>
            </IconButton>

            <Notification
              notification={appnotification}
              index={i}
              appId={value._id}
            />
          </>
        ),
      });
    });
    setRows(tableRows);
  };

  async function getApps(searchTerm = null) {
    try {

      let search = '';
      if (searchTerm) {
        search = "?searchTerm=" + searchTerm;
      }

      if (userProfile.userType === "user") {
        const userId = userProfile.userId;
        var apps1 = await apiServices.httpRequest(
          "GET",
          `team/user/${userId}`,
          null
        );

        let arrayData = [];
        const appsdata = apps1.map((value, i) => {
          const appsval = value.apps;
          appsval.map((value1) => {
            if (arrayData.indexOf(value1) < 0) {
              arrayData.push(value1.app);
            }
          });
        });
        var apps = await apiServices.httpRequest("GET", `teams/apps` + search, null);
        var notifi = await apiServices.httpRequest(
          "GET",
          `team/apps/${userId}`,
          null
        );

        createTableRows(apps, notifi);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const appsTemplate = (rowData) => {

    let logoval = "";
    if (rowData.apps[0] === "") {
      let logo_val = "bishop-placeholder-logo.jpeg"
      logoval = `${constants.appUrl}${logo_val}`;
    } else {
      //logoval = `${constants.apiUrl}${rowData.apps[0]}`
      logoval = `${rowData.apps[0]}`
    }

    return (
      <>
        <div
          onClick={() =>
            viewPlatform(
              rowData.platform ?? rowData.platform[0],
              {
                appId: rowData.id,
                title: rowData.apps[1],
                logo: rowData.apps[0],
              },
              ""
            )
          }
        >
          <div className="MuiBox-root css-go6tax" style={{ marginRight: "16px", display: "inline-flex" }}>
            <SuiAvatar src={logoval} alt={rowData.apps[1]} size="sm" variant="rounded" />
          </div>
          <SuiTypography variant="button" color="text" fontWeight="medium" verticalAlign="super" >
            {rowData.apps[1]}
          </SuiTypography>
        </div>
      </>
    )

  }

  const descriptionTemplate = (rowData) => {

    return (
      <>
        <div
          onClick={() =>
            viewPlatform(
              rowData.platform ?? rowData.platform[0],
              {
                appId: rowData.id,
                title: rowData.apps[1],
                logo: rowData.apps[0],
              },
              ""
            )
          }
        >
          <Tooltip title={rowData.description.props.title}>
            <SuiTypography variant="button" color="text" fontWeight="medium">
              {rowData.description.props.title}
            </SuiTypography>
          </Tooltip>
        </div>
      </>
    )

  }

  const onColumnClickSort = (data) => {

    var newSortOrder = data.order;
    var dataArray = data.data;

    if (currentSortOrder === newSortOrder && currentSortField === data.field) {
      return dataArray;
    }

    setCurrentSortOrder(newSortOrder);
    setCurrentSortField(data.field);

    if (data.field == "apps") {

      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.apps[1].localeCompare(b.apps[1]));
      } else {
        return dataArray.reverse((a, b) => a.apps[1].localeCompare(b.apps[1]));
      }

    } else if (data.field == "description") {
      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.description.props.title.localeCompare(b.description.props.title));
      } else {
        return dataArray.reverse((a, b) => a.description.props.title.localeCompare(b.description.props.title));
      }
    } else if (data.field == "type") {
      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.type.props.children.props.children.localeCompare(b.type.props.children.props.children));
      } else {
        return dataArray.reverse((a, b) => a.type.props.children.props.children.localeCompare(b.type.props.children.props.children));
      }
    } else if (data.field == "status") {
      if (newSortOrder === 1) {
        return dataArray.sort((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
      } else {
        return dataArray.reverse((a, b) => a.status.props.badgeContent.localeCompare(b.status.props.badgeContent));
      }
    }

    return dataArray;
  };

  return (
    <>
      <SuiBox py={3}>
        <Card>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SuiTypography variant="h6">Projects List</SuiTypography>

            <div className="search-action-btn-wrap">
              <SuiBox pr={3} className="searchContainer" >
                <SuiInput
                  placeholder="Search here..."
                  size="small"
                  onChange={debounceHandleChange}
                  name="search"
                  id="search"
                />
                <IconButton
                  size="small"
                  color="inherit"
                  className={searchClassName}
                  onClick={clearSearch}
                >
                  <Icon className="text-white">
                    close
                  </Icon>
                </IconButton>
              </SuiBox>

            </div>

          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >

            <DataTable value={rows} sortField="apps" sortOrder={1} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ padding: '10px', minWidth: '50rem' }}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
              <Column field="apps" sortable sortFunction={onColumnClickSort} body={appsTemplate} header="APPS"></Column>
              <Column field="description" sortable sortFunction={onColumnClickSort} body={descriptionTemplate} header="DESCRIPTION"></Column>
              <Column field="status" sortable sortFunction={onColumnClickSort} header="STATUS"></Column>
              <Column field="action" header="ACTION"></Column>
            </DataTable>

            {/* <Table columns={columns} rows={rows} /> */}
            {renderSettings()}
          </SuiBox>
        </Card>
      </SuiBox>
    </>
  );
}

export default TableUsers;
